import {BaseRequest} from "../../api/BaseRequest";
import JsonObject from "../../utils/json/JsonObject";
import JsonArray from "../../utils/json/JsonArray";
import WebshopItem from "../../models/WebshopItem";

/**
 * @property {function|null} onSuccess
 * @property {function|null} onFailure
 */
export class WebshopRequest extends BaseRequest {
    constructor() {
        super();

        this.onSuccess = null;
        this.onFailure = null;
    }

    createRequest() {
        return {
            method: 'get',
            url: '/webshop',
            headers: this.appUserAccessTokenHeaders()
        }
    }

    handleSuccess(response) {
        try {
            const jsonArray = new JsonArray(response.data);

            let items = [];
            jsonArray.jsonData.forEach((itemsJsonData) => {
                const itemJsonObject = new JsonObject(itemsJsonData);
                items.push(WebshopItem.parse(itemJsonObject));
            });

            this.onSuccess?.(items);
        } catch(error) {
            this.handleFailure(error);
        }
    }

    handleFailure(error) {
        this.onFailure?.(error);
    }
}