import styled from 'styled-components';
import { Link } from "react-router-dom";

export const GridItem = styled(Link)`
    box-shadow: 0px 2px 10px 0px rgba(var(--color-black), var(--alpha-10));
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgb(var(--color-white));
    padding: 40px;
    min-height: 250px;
`;

export const GridImg = styled.img`
    max-width: 200px;
    width: 100%;
    max-height: 150px;
    height: 100%;
    object-fit: contain;
`;