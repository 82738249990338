import React, { useEffect, useState, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import * as S from './Program.elements';
import { Helmet } from 'react-helmet-async';
import { Tabs, TabPanel } from 'react-tabs';
import { Header, Decoration, Card, CardList, LoadIcon, Wrapper } from '../../components';
import { ProgramsRequest } from "./ProgramsRequest";
import programDecoration1 from '../../assets/decorations/program-decoration-1.svg';
import programDecoration2 from '../../assets/decorations/program-decoration-2.svg';
import programDecoration3 from '../../assets/decorations/program-decoration-3.svg';

// Set tabsRole for styled component
S.TabNav.tabsRole = 'TabList';
S.TabLink.tabsRole = 'Tab';

function useQuery() {
    return new URLSearchParams(useLocation().search)
}

const Program = () => {

    const [tabSlug, setTabSlug] = useState(0);

    const programsRequest = useRef(null);
    const [programsData, setProgramsData] = useState(/** @var {Array.<ProgramsArray>|null} */ null);
    const [requestError, setRequestError] = useState(null);

    useEffect(() => {
        if(!programsData && !requestError) {
            programsRequest.current?.cancel();
            programsRequest.current = new ProgramsRequest();
            programsRequest.current.onSuccess = (programsItems) => {
                setProgramsData(programsItems);
            }
            programsRequest.current.onFailure = (error) => {
                setRequestError(true);
            }
            programsRequest.current.enqueue();
            
            return () => {
                //Happens when component is dismounted, so cancel request
                programsRequest.current?.cancel();
            }
        }
    }, [programsData, requestError])
    
    return (
        <>
            <Helmet>
                <title>Lustrum Albertus Magnus &mdash; Programma</title>
                <link rel="canonical" href="https://www.lustrumalbertus.nl/programma"/>
            </Helmet>

            <Header heading="Programma">
                <Decoration src={programDecoration1} bottom="0" left="0" />
                <Decoration src={programDecoration2} bottom="0" right="0" />
                <Decoration src={programDecoration3} top="0" right="0" />
            </Header>

            <Wrapper>
                {programsData && !requestError 
                    ? <FilledContent />
                    : <LoadIcon />
                }
            </Wrapper>
        </>
    );

    function FilledContent() {
        
        // React-tabs in controlled mode for query params 
        const query = useQuery()
        const filter = query.get("filter")
    
        const navigate = useNavigate()

        useEffect(() => {
            for (let i = 0; i < programsData.length; i++) {
                if(programsData[i].slug === filter){
                    setTabSlug(i);
                    localStorage.setItem('filter', `?filter=${filter}`)
                }
            }
        }, [filter])



        const tabHandler = (index) => {
            navigate(`?filter=${programsData[index].slug}`);
            setTabSlug(index);
        }

        return (
            <>
                <Tabs selectedIndex={tabSlug} onSelect={index => tabHandler(index)} selectedTabClassName='is-selected' selectedTabPanelClassName='is-selected'>
                    <S.TabNavContainer>
                        <S.TabNav>
                            {programsData.map(program => {
                                return(
                                    <S.TabLink key={program.id}>{program.title}</S.TabLink>
                                )
                            })}
                        </S.TabNav>
                    </S.TabNavContainer>

                    {programsData.map(program => {
                        return(
                            <TabPanel key={program.id}>
                                {program.perMonth.map(programMonth => {
                                    return(
                                        <CardList key={programMonth.month} heading={programMonth.month}>
                                            {programMonth.items.map(programItem => {
                                                const date = new Date(programItem.date * 1000);
                                                let day = date.toLocaleString('nl-NL', { weekday: 'short' });
                                                day = day[0].toUpperCase() + day.slice(1);
                                                let dateInCard = `${day} ${programItem.startTime} - ${programItem.endTime} uur`;
                                                let month = date.toLocaleString('nl-NL', { month: 'short' }).replace(/\.$/, "");
                                                return (
                                                    <Card key={programItem.id} link={programItem.slug} heading={programItem.title} image={programItem.imageUrl} month={month} day={date.getDate()} date={dateInCard} address={programItem.location} text={programItem.introText}/>
                                                )
                                            })}
                                        </CardList>
                                    )
                                })}
                            </TabPanel>
                        )
                    })}                    
                </Tabs>
            </>
        )
    }
}

export default Program
