import styled from 'styled-components';
import { Container } from '../../components';

export const Content = styled(Container)`
    background-color: rgb(var(--color-white));
    padding: 40px 30px;
    margin-top: -80px;
    margin-bottom: 100px;
    position: relative;
    box-shadow: 0px 2px 10px 0px rgba(var(--color-black), var(--alpha-10));
    border-radius: 5px;
    max-width: 840px;

    @media screen and (max-width: 1152px) {
        margin-top: 85px;
    }

    @media screen and (max-width: 768px) {
        padding: 30px 20px;
        padding-bottom: 40px;
        margin-bottom: 0;
    }

    @media screen and (max-width: 425px) {
        padding: 20px 10px;
    }
`;

export default Content;
