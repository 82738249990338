import styled from 'styled-components';

const Container = styled.div`
    max-width: 1440px;
    margin: 0 auto;
    padding-left: 40px;
    padding-right: 40px;
    width: 100%;

    @media screen and (max-width: 768px) {
        padding-left: 20px;
        padding-right: 20px;
    }

    @media screen and (max-width: 425px) {
        padding-left: 10px;
        padding-right: 10px;
    }
`;

export default Container;