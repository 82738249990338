import styled from 'styled-components';

const Background = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(var(--color-dark-cyan));
    background-image: linear-gradient(150deg, rgba(var(--color-dark-cyan), var(--alpha-50)) 50%, rgba(var(--color-dark-cyan), var(--alpha-50)) 50%), url(${props => props.img});
    background-size: cover;
    background-position: center;
`;

export default Background;