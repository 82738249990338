import styled, { css } from 'styled-components';

const Text = styled.p`
    color: ${props => props.small ? 'rgba(var(--color-black), var(--alpha-70))' : 'rgb(var(--color-black))'};
    font-size: ${props => props.small ? 'var(--font-size-small)' : 'var(--font-size-default)'};
    margin-top: ${props => props.small ? '5px' : '20px'};
    margin-bottom: ${props => props.small ? '5px' : '20px'};
    line-height: ${props => props.small ? '1.25' : '1.35'};
    text-align: ${props => props.align};
    
    ${props => props.truncate ? `${truncate}` : ''};
    ${props => props.small ? `${small}` : ''};
    ${props => props.list ? `${list}` : ''};

    a {
        text-decoration: underline;
        color: rgb(var(--color-link));
        transition: color .2s ease;

        &:hover {
            color: rgb(var(--color-black));
        }
    }
`;

const truncate = css`
    display: -webkit-box;
    line-clamp: 4; 
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
    overflow: hidden;
`;

const small = css`

    &:first-of-type {
        margin: 0;
    }

    svg {
        color: rgb(var(--color-dark-cyan));
        font-size: 2rem; 
        vertical-align: middle;
        margin-right: 10px;
    }
`;

const list = css`
    margin-bottom: 40px;

    li {
        margin: 10px 0;
    }

    svg {
        color: rgb(var(--color-dark-cyan));
        vertical-align: middle;
        margin-right: 10px;
    }
`;

export default Text;