import styled from 'styled-components';

export const Figure = styled.figure`
    position: relative;
    max-width: 70%;
    height: auto;
    margin: 40px auto;

    @media screen and (max-width: 768px) {
        max-width: 100%;
    }
`;

export const Img = styled.img`
    width: 100%;
    height: auto;
    object-fit: cover;
`;

const Image = (props) => {

    return (
        <Figure>
            <Img src={props.image} alt={props.alt || ''} loading="lazy" />
            {props.children}
        </Figure>
    )
}

export default Image
