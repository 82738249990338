import {BaseRequest} from "../../api/BaseRequest";
import JsonObject from "../../utils/json/JsonObject";
import JsonArray from "../../utils/json/JsonArray";
import ContactObject from "../../models/ContactObject";

/**
 * @property {function|null} onSuccess
 * @property {function|null} onFailure
 */
export class ContactRequest extends BaseRequest {
    constructor() {
        super();

        this.onSuccess = null;
        this.onFailure = null;
    }

    createRequest() {
        return {
            method: 'get',
            url: '/content?screen=contact',
            headers: this.appUserAccessTokenHeaders()
        }
    }

    handleSuccess(response) {
        try {
            const jsonArray = new JsonArray(response.data);

            let content = [];
            jsonArray.jsonData.forEach((contentJsonData) => {
                const contactJsonObject = new JsonObject(contentJsonData);
                content.push(ContactObject.parse(contactJsonObject));
            });

            let object = content.reduce((obj, item) => Object.assign(obj, { [item.key]: item.value }), {});

            this.onSuccess?.(object);
        } catch(error) {
            this.handleFailure(error);
        }
    }

    handleFailure(error) {
        this.onFailure?.(error);
    }
}