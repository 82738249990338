import React, { useEffect, useState, useRef } from 'react';
import { Header, Decoration, PhotoGrid, PhotoGridItem, LoadIcon, Wrapper } from '../../components';
import { PhotoAlbumsRequest } from "./PhotoAlbumsRequest";
import { Helmet } from 'react-helmet-async';
import { Link } from 'react-router-dom';
import themeAndInformationDecoration1 from '../../assets/decorations/theme-and-information-decoration-1.svg';
import themeAndInformationDecoration2 from '../../assets/decorations/theme-and-information-decoration-2.svg';
import themeAndInformationDecoration3 from '../../assets/decorations/theme-and-information-decoration-3.svg';

const PhotoAlbums = () => {

    const photoAlbumsRequest = useRef(null);
    const [photoAlbumsData, setPhotoAlbumsData] = useState(/** @var {Array.<PhotoAlbumsArray>|null} */ null);
    const [requestError, setRequestError] = useState(null);

    useEffect(() => {
        if(!photoAlbumsData && !requestError) {
            photoAlbumsRequest.current?.cancel();
            photoAlbumsRequest.current = new PhotoAlbumsRequest();
            photoAlbumsRequest.current.onSuccess = (photoAlbumsItems) => {
                setPhotoAlbumsData(photoAlbumsItems);
            }
            photoAlbumsRequest.current.onFailure = (error) => {
                setRequestError(true);
            }
            photoAlbumsRequest.current.enqueue();
            
            return () => {
                //Happens when component is dismounted, so cancel request
                photoAlbumsRequest.current?.cancel();
            }
        }
    }, [photoAlbumsData, requestError])
    
    return (
        <> 
            <Helmet>
                <title>Lustrum Albertus Magnus &mdash; Fotoalbums</title>
                <link rel="canonical" href="https://www.lustrumalbertus.nl/thema-en-informatie/fotoalbums"/>
            </Helmet>

            <Header heading="Fotoalbums">
                <Decoration src={themeAndInformationDecoration1} bottom="0" left="0" />
                <Decoration src={themeAndInformationDecoration2} top="0" right="0" />
                <Decoration src={themeAndInformationDecoration3} bottom="0" right="0" />
            </Header>

            <Wrapper>
                {photoAlbumsData && !requestError 
                    ? <FilledContent />
                    : <LoadIcon />
                }
            </Wrapper>
        </>
    );

    function FilledContent() {

        return (
            <PhotoGrid>
                {photoAlbumsData.map(item => {
                    return(
                        <Link to={item.slug} key={item.id}>
                            <PhotoGridItem title={item.title} date={item.date} image={item.imageUrl} />
                        </Link>
                    )
                })}
            </PhotoGrid>
        )
    }
}

export default PhotoAlbums
