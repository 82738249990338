import styled, { css } from 'styled-components';
import { Container } from '../../components';
import { Link , NavLink} from 'react-router-dom';
import Burger from '@animated-burgers/burger-squeeze'
import '@animated-burgers/burger-squeeze/dist/styles.css' 

export const TopNav = styled.nav`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 120px;
    z-index: 99;
    transition: background-color .2s ease, box-shadow .2s ease;
    
    @media screen and (min-width: 1153px){
        ${({offset}) => offset > 80 ? `${activeNav}` : ''};
    }

    @media screen and (max-width: 1152px) {
        height: 85px;
    }
`;

const activeNav = css`
    background-color: rgb(var(--color-dark-cyan));
    box-shadow: 0px 4px 8px 0px rgba(var(--color-black), var(--alpha-20));
`;

export const TopNavContainer = styled(Container)`
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;
`;

export const TopNavList = styled.ul`
    position: relative;
    display: flex;
    align-items: center;
    list-style: none;
    text-align: center;
    
    @media screen and (max-width: 1152px) {
        display: block;
        position: absolute;
        overflow-y: scroll;
        padding: 120px 20px;
        top: 0;
        opacity: ${({ isOpen }) => (isOpen ? 1 : 0)};
        left: ${({ isOpen }) => (isOpen ? 0 : '-100%')};
        flex-direction: column;
        justify-content: center;
        width: 100%;
        min-height: 100vh;
        height: 100%;
        background-color: rgb(var(--color-dark-cyan));
        transition: opacity .2s linear;
    }
`;

export const TopNavItem = styled.li`
    z-index: 99;
    position: relative;

    @media screen and (min-width: 1153px) {
        /* Line between items */
        &:not(:first-child):before  {
            position: absolute;
            content:'';
            top: 50%;
            transform: translateY(-50%);
            left: 0;
            width: 2px;
            height: 24px;
            background-color: rgba(var(--color-ivory), var(--alpha-40));
        }
    }
`;

export const TopNavLink = styled(NavLink)`
    display: inline-block;
    line-height: 1.3;
    font-family: var(--font-raleway);
    font-size: var(--font-size-nav);
    font-weight: var(--bold);
    color: rgb(var(--color-ivory));
    padding: 20px;
    text-shadow: 2px 2px 4px rgba(var(--color-black), var(--alpha-20));
    cursor: pointer;

    svg {
        vertical-align: middle;
        filter: drop-shadow(2px 2px 4px rgba(var(--color-black), var(--alpha-20)));
        margin-left: 10px;
        transition: transform .2s ease;
    }

    &[aria-expanded='true'] {
        svg {
            transform: rotate(180deg);
        }
    }

    /* Hover effect */
    &:after {
        content: '';
        display: block;
        width: 0;
        height: 2px;
        background: rgb(var(--color-orange));
        transition: width .3s;
    }

    @media screen and (min-width: 1152px){
        &:hover:after {
            width: 100%;
        }
    }

    &.active {

        &:after {
            width: 100%;
        }
    }
`;

export const Accordion = styled.div`
    margin: 0;
    padding: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
`;

export const AccordionTitle = styled.div`
    display: inline-block;
    width: fit-content;
    line-height: 1.3;
    font-family: var(--font-raleway);
    font-size: var(--font-size-nav);
    font-weight: var(--bold);
    color: rgb(var(--color-ivory));
    padding: 20px;
    text-shadow: 2px 2px 4px rgba(var(--color-black), var(--alpha-20));
    cursor: pointer;
    
    /* Hover effect */
    &:after {
        content: '';
        display: block;
        width: ${({ isOpen }) => (isOpen ? '100%' : '0')};
        height: 2px;
        background: rgb(var(--color-orange));
        transition: width .2s;
    }
    
    &.active {

        &:after {
            width: 100%;
        }
    }

    svg {
        vertical-align: middle;
        filter: drop-shadow(2px 2px 4px rgba(var(--color-black), var(--alpha-20)));
        margin-left: 10px;
        transition: transform .2s ease;

        &[aria-expanded='true'] {
            transform: rotate(180deg);
        }
    }
`;

export const AccordionContent = styled.div`
    overflow: hidden;
    max-height: 1000px;
    transition: max-height 1s ease-in-out;
    display: flex;
    align-items: center;
    flex-direction: column;

    &[aria-expanded='false'] {
        max-height: 0px;
        transition: max-height 0.5s cubic-bezier(0, 1, 0, 1);
    }
`;

export const NavAccordionLink = styled(TopNavLink)`
    font-size: var(--font-size-nav-sub);
    width: fit-content;
`;

export const NavDropdown = styled.ul`
    visibility: hidden;
    opacity: 0;
    transition: transform 0.2s ease, opacity 0.2s ease, visibility 0s linear 0.2s;
	will-change: transform;
    position: absolute;
    width: 100%;
    background-color: rgb(var(--color-white));
    border-radius: 5px;
    display: flex;
    text-align: left;
    flex-direction: column;
    box-shadow: 0px 2px 10px 0px rgba(var(--color-black), var(--alpha-20));
    
    &.visible {
        transform: translateY(-0.3rem);
        transition: transform 0.2s ease, opacity 0.2s ease, visibility 0s linear 0s;
        visibility: visible;
        opacity: 1;
    }
`;

export const NavDropdownLink = styled(NavLink)`
    color: rgb(var(--color-dark-cyan)); 
    width: 100%;
    padding: 14px 12px;
    transition: background-color .2s ease;

    &:first-child {
        border-radius: 5px 5px 0 0;
    }

    &:last-child {
        border-radius: 0 0 5px 5px;
    }

    &:not(:last-child) {
        border-bottom: 1px solid rgba(var(--color-grey), var(--alpha-30));
    }

    &:hover {
        background-color: rgb(var(--color-ivory));
    }
`;

export const NavDropdownItem = styled.li`
    font-size: var(--font-size-nav-sub);
    font-weight: var(--bold);
    line-height: 1.3;
    display: inline-block;

    /* Hover effect */
    &:after {
        content: '';
        display: block;
        width: ${({ isOpen }) => (isOpen ? '100%' : '0')};
        height: 2px;
        background: rgb(var(--color-orange));
        transition: width .3s;
    }

    ${NavDropdownLink}.active & {

        &:after {
            width: 100%;
        }
    }
`;

export const Logo = styled(Link)`
    width: 80px;
    height: 80px;

    @media screen and (max-width: 1152px) {
        width: 60px;
        height: 60px;
    }
`;

export const Img = styled.img`
    width: 80px;
    height: 80px;

    @media screen and (max-width: 1152px) {
        width: 60px;
        height: 60px;
    }
`;

export const EmptyNav = styled.div`
    width: 80px;
    height: 80px;

    @media screen and (max-width: 1152px) {
        width: 60px;
        height: 60px;
    }
`;

export const Hamburger = styled(Burger)`
    font-size: 12px;
    display: none;
    z-index: 100;
    
    @media screen and (max-width: 1152px) {
        display: block;
    }

    /* Remove box shadow when Hamburger is open from invisible middle line */
    &.open { .burger-lines { box-shadow: none; } }
    
    /* Add color and box shadow to all three lines of Hamburger */
    .burger-lines {
        background-color: rgb(var(--color-ivory));
        box-shadow: 0px 4px 8px 0px rgba(var(--color-black), var(--alpha-20));

        &:after, &:before {
            box-shadow: 0px 4px 8px 0px rgba(var(--color-black), var(--alpha-20));
        }
    }
`;

export const MenuDecoration1 = styled.img`
    display: none;

    @media screen and (max-width: 1152px) {
        display: block;
        position: fixed;
        top: -20px;
        left: -20px;
    }
`;

export const MenuDecoration2 = styled.img`
    display: none;

    @media screen and (max-width: 1152px) {
        display: block;
        position: fixed;
        bottom: 0;
        right: 0;
    }
`;