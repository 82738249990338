import styled from 'styled-components';

const Heading = styled.h2`
    font-family: var(--font-raleway);
    color: rgb(var(--color-black));
    font-size: var(--font-size-heading);
    font-weight: var(--semi-bold);
    margin-bottom: ${props => props.marginbottom ? '5px' : '10px'};
    line-height: 1.25;
`;

export default Heading;