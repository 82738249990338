import React, { useEffect, useState, useRef } from 'react';
import { Header, Decoration, Content, Button, ButtonWrapper, Heading, Text, Input, Title, Wrapper, LoadIcon } from '../../components';
import { ContactRequest } from "./ContactRequest";
import { Helmet } from 'react-helmet-async';
import contactDecoration1 from '../../assets/decorations/contact-decoration-1.svg';
import contactDecoration2 from '../../assets/decorations/contact-decoration-2.svg';
import { MdPhone, MdMail, MdLocationPin } from 'react-icons/md'; 
import { Formik, Form } from "formik";
import * as Yup from "yup";
import Modal from 'react-modal';
import './modal.css';
import axios from "axios";

Modal.setAppElement('#root');

const Contact = () => {

    const contactRequest = useRef(null);
    const [contactData, setContactData] = useState(/** @var {ContactObject|null} */ null);
    const [requestError, setRequestError] = useState(null);

    useEffect(() => {
        if(!contactData && !requestError) {
            contactRequest.current?.cancel();
            contactRequest.current = new ContactRequest();
            contactRequest.current.onSuccess = (contactObject) => {
                setContactData(contactObject);
            }
            contactRequest.current.onFailure = (error) => {
                setRequestError(true);
            }
            contactRequest.current.enqueue();
            
            return () => {
                //Happens when component is dismounted, so cancel request
                contactRequest.current?.cancel();
            }
        }
    }, [contactData, requestError])

    const [modalIsOpen, setIsOpen] = useState(false);

    const openModal = () => setIsOpen(true);
    const closeModal = () => setIsOpen(false);

    useEffect(() => {
        modalIsOpen && (document.body.style.overflow = 'hidden');
        !modalIsOpen && (document.body.style.overflow = 'unset');
    }, [modalIsOpen]);

    return (
        <> 
            <Helmet>
                <title>Lustrum Albertus Magnus &mdash; Contact</title>
                <link rel="canonical" href="https://www.lustrumalbertus.nl/contact"/>
            </Helmet>

            <Header heading="Contact">
                <Decoration src={contactDecoration1} height="true" top="0" left="0" />
                <Decoration src={contactDecoration2} bottom="0" right="0" />
            </Header>

            <Wrapper>
                {contactData && !requestError
                    ? <FilledContent />
                    : <LoadIcon />
                }
            </Wrapper>

        </>
    )

    function FilledContent() {

        return (
            <>
                <Content>
                    <Heading>{contactData.contactTitle}</Heading>
                    <Text>{contactData.contactText}</Text>
                    
                    <Text list="true" as="ul">
                        <li><a href={"mailto:" + contactData.contactEmail + "?SUBJECT=Lustrum"}><MdMail/>{contactData.contactEmail}</a></li>
                        <li><a href={"tel:" + contactData.contactPhone}><MdPhone/>{contactData.contactPhone}</a></li>
                        <li><a href={contactData.contactAddressMapsUrl} rel="noreferrer" target="_blank"><MdLocationPin/>{contactData.contactAddress}</a></li>
                    </Text>

                    <Heading>{contactData.contactFormTitle}</Heading>
                    <Text>{contactData.contactFormText}</Text>

                    <Formik
                        initialValues={{
                            name: "",
                            email: "",
                            text: "",
                        }}

                        validationSchema={Yup.object({
                            name: Yup.string()
                                .max(50, "Je kunt maximaal 50 karakters opgeven.")
                                .required("Dit is een verplicht veld."),
                            email: Yup.string()
                                .email("Vul een geldig e-mail adres in.")
                                .required("Dit is een verplicht veld."),
                            text: Yup.string()
                                .max(500, "Je kunt maximaal 500 karakters opgeven.")
                                .required("Dit is een verplicht veld."),
                        })}
                        
                        onSubmit={async (values, { setSubmitting, resetForm }) => {
                            axios.post(`${process.env.REACT_APP_API_BASE_URL}/contactform`, (JSON.stringify(values, null, 2)), {headers: { 'App-User': 'AAAA-BBBB-CCCC-DDDD-EEEE' }});
                            openModal()
                            resetForm({})
                            setSubmitting(false);
                        }}
                    >
                        <Form>
                            <Input
                                label="Naam"
                                id="name"
                                name="name"
                                type="text"
                                placeholder="Naam"
                            />
                            <Input
                                label="E-mailadres"
                                id="email"
                                name="email"
                                type="email"
                                placeholder="E-mailadres"
                            />
                            <Input
                                as="textarea"
                                textarea="true"
                                label="Idee of suggestie"
                                id="text"
                                name="text"
                                type="text"
                                placeholder="Typ hier jouw idee of suggestie"
                                rows="7"
                            />
                            <Text align="right">*verplicht</Text>

                            <ButtonWrapper justifycontent="flex-end">
                                <Button as="button" type="submit">Versturen</Button>
                            </ButtonWrapper>
                        </Form>
                    </Formik>
                </Content>

                <Modal
                    isOpen={modalIsOpen}
                    onRequestClose={closeModal}
                    contentLabel="Bevestiging"
                    className="Modal"
                    overlayClassName="Overlay"
                    closeTimeoutMS={200}
                >
                    <Title sub="true" as="h2">Idee verzonden</Title>
                    <Text>Bedankt voor jouw idee! Wij doen ons best om jouw idee mee te nemen.</Text>
                    <Button onClick={closeModal} as="button" type="submit">OK</Button>
                </Modal>
            </>
        )
    }
}

export default Contact