import React, { useEffect, useState, useRef } from 'react';
import * as S from './Detail.elements';
import { DetailRequest } from "./DetailRequest";
import { HiOutlineChevronLeft } from 'react-icons/hi';
import { MdLocationOn, MdOutlineAccessTimeFilled } from 'react-icons/md';
import { Header, Decoration, Button, Text, Paragraph, Calendar, Content, Title, LoadIcon, Wrapper } from '../../components';
import { NoMatch } from '../../pages';
import { Helmet } from 'react-helmet-async';
import detailDecoration1 from '../../assets/decorations/detail-decoration-1.svg';
import detailDecoration2 from '../../assets/decorations/detail-decoration-2.svg';
import detailDecoration3 from '../../assets/decorations/detail-decoration-3.svg';

const Detail = () => {

    const detailRequest = useRef(null);
    const [detailData, setDetailData] = useState(/** @var {DetailObject|null} */ null);
    const [requestError, setRequestError] = useState(null);
    
    useEffect(() => {
        if(!detailData && !requestError) {
            detailRequest.current?.cancel();
            detailRequest.current = new DetailRequest();
            detailRequest.current.onSuccess = (detailObject) => {
                setDetailData(detailObject);
            }
            detailRequest.current.onFailure = (error) => {
                setRequestError(true);
            }
            detailRequest.current.enqueue();
            
            return () => {
                //Happens when component is dismounted, so cancel request
                detailRequest.current?.cancel();
            }
        }
    }, [detailData, requestError])

    if(requestError) return <NoMatch/>;

    return (
        <>
            <Helmet>
                <title>Lustrum Albertus Magnus &mdash; {detailData != null ? detailData.title : 'Programma'}</title>
                <link rel="canonical" href={"https://www.lustrumalbertus.nl/programma/" + (detailData != null ? detailData.slug : '') }/>
            </Helmet>

            <Header>
                <Decoration src={detailDecoration1} top="0" left="0" />
                <Decoration src={detailDecoration2} top="0" right="0" />
                <Decoration src={detailDecoration3} bottom="0" right="0" />
            </Header>

            <Wrapper>
                {detailData && !requestError 
                    ? <FilledContent />
                    : <LoadIcon />
                }
            </Wrapper>
        </>
    );

    function FilledContent() {

        const date = new Date(detailData.date * 1000);
        let day = date.toLocaleString('nl-NL', { weekday: 'short' });
        day = day[0].toUpperCase() + day.slice(1);
        let month = date.toLocaleString('nl-NL', { month: 'short' }).replace(/\.$/, "");

        return (
            <Content>
                <S.BackBtn to={localStorage.getItem('filter') ? `/programma/${localStorage.getItem('filter')}` : '/programma' }>
                    <HiOutlineChevronLeft /> Terug naar programma
                </S.BackBtn>

                <S.Header>
                    <Title sub="true">{detailData.title}</Title> 

                    <S.Flex>
                        <Calendar month={month} day={date.getDate()}/>
                        <ul>
                            <Text small="true" as="li"><MdOutlineAccessTimeFilled />{day} {detailData.startTime} - {detailData.endTime} uur</Text>
                            {detailData.location && <Text small="true" as="li"><MdLocationOn />{detailData.location}</Text>}
                        </ul>
                    </S.Flex>
                </S.Header>

                <Text>{detailData.introText}</Text>

                <S.Figure>
                    <S.Img src={detailData.imageUrl || ''} loading="lazy" alt=""/>
                </S.Figure>

                <Paragraph dangerouslySetInnerHTML={{__html: detailData.text}} />

                <Button as="a" mt="true" href={detailData.ticketURL} rel="noreferrer" target="_blank">Koop tickets</Button>
            </Content>
        )
    }
}

export default Detail
