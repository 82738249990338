/**
 * @property {string} id
 * @property {string} title
 * @property {string} date
 * @property {string} imageUrl
 */

export default class PhotoAlbumObject {
    constructor() {
        this.id = "";
        this.title = "";
        this.date = "";
        this.imageUrl = "";
    }

    /**
     * @param {JsonObject} jsonObject
     */
    parse(jsonObject) {
        this.id = jsonObject.string("id");
        this.title = jsonObject.stringDefault("title", "");
        this.date = jsonObject.stringDefault("date", "");
        this.imageUrl = jsonObject.stringDefault("imageUrl", "");
    }

    /**
     * @param {JsonObject} jsonObject
     * @return {PhotoAlbumObject}
     */
    static parse(jsonObject) {
        const photoAlbumObject = new PhotoAlbumObject();
        photoAlbumObject.parse(jsonObject);
        return photoAlbumObject;
    }
}