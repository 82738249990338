import * as S from './GridItem.elements';

const GridItem = (props) => {

    return (
        <S.GridItem as="a" href={props.link || '#'} rel="noreferrer" target="_blank">
            <S.GridImg src={props.image} alt={props.alt || ''} draggable="false" loading="lazy"/>
        </S.GridItem>
    )
}

export default GridItem
