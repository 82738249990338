import styled from 'styled-components';
import { Container, Heading } from '../../components';

export const CardListContainer = styled(Container)`
    max-width: 840px;
    padding: 100px 0 20px;

    &:last-of-type {
        padding-bottom: 100px;
    }
    
    @media screen and (max-width: 768px) {
        padding: 20px 0 20px;

        &:last-of-type {
            padding-bottom: 20px;
        }
    }
`;

export const ListHeading = styled(Heading)`
    /* border-left: 3px solid rgb(var(--color-orange)); */
    padding-left: 20px;
    margin-bottom: 20px;
    position: relative;
    text-transform: capitalize;
    
    &:before {
        position: absolute;
        top: 0;
        left: 0;
        content: '';
        border-radius: 3px;
        width: 3px;
        height: 100%;
        background-color: rgb(var(--color-orange));
    }

    @media screen and (max-width: 768px) {
        margin-left: 20px;
        margin-bottom: 10px;
    }

    @media screen and (max-width: 425px) {
        margin-left: 10px;
    }
`;

export const CardList = styled.section`
    box-shadow: 0px 2px 10px 0px rgba(var(--color-black), var(--alpha-10));
    border-radius: 5px;
`;