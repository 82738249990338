import * as S from './PhotoGridItem.elements';
import { Text } from '../../components';

const PhotoGridItem = (props) => {

    const date = new Date(props.date * 1000);
    let formattedDate = date.toLocaleString('nl-NL', { dateStyle: 'long' });

    return (
        <S.Item>
            <S.Figure>
                <S.Image src={props.image} alt='' />
            </S.Figure>
            {props.title && 
                <S.Body>
                    <S.Title>{props.title}</S.Title>
                    <Text small="true">{formattedDate}</Text>
                </S.Body>
            }
        </S.Item>
    )
}

export default PhotoGridItem
