import styled from 'styled-components';

export const MapContainer = styled.div`
    max-width: 1440px;
    min-height: 600px;
    margin:-80px auto 100px;
    position: relative;
    padding-left: 40px;
    padding-right: 40px;
    
    @media screen and (max-width: 1152px) {
        margin-top: 85px;
    }
    
    @media screen and (max-width: 768px) {
        margin-bottom: 40px;
        padding: 0;
    }

    .react-transform-component {
        width: 100%;
        height: 100%;
    }

    .react-transform-element {
        width: 100%;
        height: 100%;
    }

    .react-transform-wrapper {
        border-radius: 5px;
        box-shadow: 0px 2px 10px 0px rgba(var(--color-black), var(--alpha-10));
        background-color: #fff;
        width: 100% !important;
        height: 100% !important;
    }
`;

export const Img = styled.img`
    border-radius: 5px;
    width: 100%;
    height: auto;
`;