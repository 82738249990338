import React, { useEffect, useState, useRef } from 'react';
import { Header, Decoration, Grid, GridItem, LoadIcon, Wrapper } from '../../components';
import { PartnersRequest } from "./PartnersRequest";
import { Helmet } from 'react-helmet-async';
import partnersDecoration1 from '../../assets/decorations/partners-decoration-1.svg';
import partnersDecoration2 from '../../assets/decorations/partners-decoration-2.svg';

const Partners = () => {

    const partnersRequest = useRef(null);
    const [partnersData, setPartnersData] = useState(/** @var {Array.<PartnersArray>|null} */ null);
    const [requestError, setRequestError] = useState(null);

    useEffect(() => {
        if(!partnersData && !requestError) {
            partnersRequest.current?.cancel();
            partnersRequest.current = new PartnersRequest();
            partnersRequest.current.onSuccess = (partnersItems) => {
                setPartnersData(partnersItems);
            }
            partnersRequest.current.onFailure = (error) => {
                setRequestError(true);
            }
            partnersRequest.current.enqueue();
            
            return () => {
                //Happens when component is dismounted, so cancel request
                partnersRequest.current?.cancel();
            }
        }
    }, [partnersData, requestError])
    
    return (
        <> 
            <Helmet>
                <title>Lustrum Albertus Magnus &mdash; Partners</title>
                <link rel="canonical" href="https://www.lustrumalbertus.nl/partners"/>
            </Helmet>

            <Header heading="Partners">
                <Decoration src={partnersDecoration1} height="true" bottom="0" left="0" />
                <Decoration src={partnersDecoration2} height="true" top="0" right="0" />
            </Header>

            <Wrapper>
                {partnersData && !requestError 
                    ? <FilledContent />
                    : <LoadIcon />
                }
            </Wrapper>
        </>
    );

    function FilledContent() {

        return (
            <>
                {partnersData.map(item => {
                    return(
                        <Grid key={item.id} title={item.title}>
                            {item.items.map(gridItem => {
                                return(
                                    <GridItem key={gridItem.id} link={gridItem.url} image={gridItem.imageUrl} alt={gridItem.title}/>
                                )
                            })}
                        </Grid>
                    )
                })}
            </>
        )
    }
}

export default Partners
