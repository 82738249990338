/**
 * @property {string} id
 * @property {string} title
 * @property {string} slug
 * @property {Array.<ThemeAndInformationParagraphs>} paragraphs
 */
import JsonObject from "../utils/json/JsonObject";

export default class ThemeAndInformationObject {
    constructor() {
        this.id = "";
        this.title = "";
        this.slug = "";
        this.paragraphs = [];
    }

    /**
     * @param {JsonObject} jsonObject
     */
    parse(jsonObject) {
        this.id = jsonObject.string("id");
        this.title = jsonObject.stringDefault("title", "");
        this.slug = jsonObject.stringDefault("slug", "");

        const paragraphsJsonArray = jsonObject.array("paragraphs");
        this.paragraphs = [];
        paragraphsJsonArray.jsonData.forEach((paragraphsJsonData) => {
            const paragraphsJsonObject = new JsonObject(paragraphsJsonData);
            this.paragraphs.push(ThemeAndInformationParagraph.parse(paragraphsJsonObject))
        })
    }

    /**
     * @param {JsonObject} jsonObject
     * @return {ThemeAndInformationObject}
     */
    static parse(jsonObject) {
        const themeAndInformationObject = new ThemeAndInformationObject();
        themeAndInformationObject.parse(jsonObject);
        return themeAndInformationObject;
    }
}


/**
 * @property {string} id
 * @property {string} type
 * @property {string} text
 * @property {string} imageUrl
 * @property {string} youtubeURL
 */
export class ThemeAndInformationParagraph {
    constructor() {
        this.id = "";
        this.type = "";
        this.text = "";
    }

    /**
     * @param {JsonObject} jsonObject
     */
    parse(jsonObject) {
        this.id = jsonObject.string("id");
        this.type = jsonObject.stringDefault("type", "");
        this.text = jsonObject.stringDefault("text", "");
        this.imageUrl = jsonObject.stringDefault("imageUrl", "");
        this.youtubeURL = jsonObject.stringDefault("youtubeURL", "");
    }

    /**
     * @param {JsonObject} jsonObject
     * @return {ThemeAndInformationParagraph}
     */
    static parse(jsonObject) {
        const themeAndInformationParagraph = new ThemeAndInformationParagraph();
        themeAndInformationParagraph.parse(jsonObject);
        return themeAndInformationParagraph;
    }
}