import * as S from './Grid.elements';
import { Title } from '../../components';

const Grid = (props) => {

    return (
        <S.GridContainer>
            {props.title 
                ? <Title sub="true" margin="true" as="h2">{props.title}</Title> 
                : null 
            }
            <S.Grid>
                {props.children}
            </S.Grid>
        </S.GridContainer>
    )
}

export default Grid
