import GlobalStyle from './GlobalStyles';
import { Routes, Route, Outlet } from 'react-router-dom';
import { Home, Webshop, Terrain, Partners, PhotoAlbums, Program, Contact, NoMatch } from './pages';
import { ThemeAndInformation, Detail, PhotoAlbum } from './layouts';
import { TopNav, Footer, ScrollToTop } from './components';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import background from './assets/background.svg';

import styled from 'styled-components';

export const StyledLayout = styled.div`
    min-height: 100vh;
    display: flex;
    flex-direction: column;

    background-image: url(${background});
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;

    @media screen and (max-width: 1152px) {
        background-attachment: inherit;
    }
`;

function Layout() {
    return (
        <StyledLayout>
            <GlobalStyle /> 
            <ScrollToTop /> 
            <TopNav />

            <Outlet />

            <Footer />
        </StyledLayout>
    );
}

function App() {
    return (
        <HelmetProvider>
            <Helmet>
                <title>Lustrum Albertus Magnus</title>
                <link rel="canonical" href="https://www.lustrumalbertus.nl/"/>
            </Helmet>

            <Routes>
                <Route path="/" element={<Layout />}>

                    <Route index element={<Home />} />

                    <Route path="programma" element={<Program />} />
                    <Route path="programma/:slug" element={<Detail />} />

                    <Route path="thema-en-informatie" element={<NoMatch />} />
                    <Route path="thema-en-informatie/fotoalbums" element={<PhotoAlbums />} />
                    <Route path="thema-en-informatie/fotoalbums/:slug" element={<PhotoAlbum />} />
                    <Route path="thema-en-informatie/:slug" element={<ThemeAndInformation />} />

                    <Route path="kaart" element={<Terrain />} />
                    <Route path="webshop" element={<Webshop />} />
                    <Route path="partners" element={<Partners />} />
                    <Route path="contact" element={<Contact />} />

                    <Route path="*" element={<NoMatch />} />

                </Route>
            </Routes>
        </HelmetProvider>
    ); 
}

export default App; 