import React from 'react'
import { Header, Decoration, Heading, Text, Button, Content } from '../../components';
import themeAndInformationDecoration1 from '../../assets/decorations/theme-and-information-decoration-1.svg';
import themeAndInformationDecoration2 from '../../assets/decorations/theme-and-information-decoration-2.svg';
import themeAndInformationDecoration3 from '../../assets/decorations/theme-and-information-decoration-3.svg';

const NoMatch = (props) => {
    return (
        <> 
            <Header heading="Oeps!">
                <Decoration src={themeAndInformationDecoration1} bottom="0" left="0" />
                <Decoration src={themeAndInformationDecoration2} top="0" right="0" />
                <Decoration src={themeAndInformationDecoration3} bottom="0" right="0" />
            </Header>

            <Content>

                <Heading>Er is iets fout gegaan</Heading>
                <Text>Het lijkt erop dat deze pagina niet bestaat.</Text>

                <Button to="/">Ga terug naar Home</Button>

            </Content>
            
        </>
    )
}

export default NoMatch
