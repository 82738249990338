import React, { useEffect, useState, useRef } from 'react';
import placeholder from '../../assets/placeholder.svg';
import { ThemeAndInformationRequest } from "./ThemeAndInformationRequest";
import { Header, Decoration, Content, Paragraph, Image, VideoLink, LoadIcon, Wrapper } from '../../components';
import { NoMatch } from '../../pages';
import { useParams } from "react-router-dom";
import { Helmet } from 'react-helmet-async';
import themeAndInformationDecoration1 from '../../assets/decorations/theme-and-information-decoration-1.svg';
import themeAndInformationDecoration2 from '../../assets/decorations/theme-and-information-decoration-2.svg';
import themeAndInformationDecoration3 from '../../assets/decorations/theme-and-information-decoration-3.svg';


const ThemeAndInformation = () => {

    let { slug } = useParams();  
    const themeAndInformationRequest = useRef(null);
    const [themeAndInformationData, setThemeAndInformationData] = useState(/** @var {ThemeAndInformationObject|null} */ null);
    const [requestError, setRequestError] = useState(null);
    
    useEffect(() => {
        // If slug changes, set data state to null to pass next Effect
        setThemeAndInformationData(null);
    }, [slug])
    
    useEffect(() => {
        if(!themeAndInformationData && !requestError) {
            themeAndInformationRequest.current?.cancel();
            themeAndInformationRequest.current = new ThemeAndInformationRequest();
            themeAndInformationRequest.current.onSuccess = (themeAndInformationObject) => {
                setThemeAndInformationData(themeAndInformationObject);
            }
            themeAndInformationRequest.current.onFailure = (error) => {
                setRequestError(true);
            }
            themeAndInformationRequest.current.enqueue();
            
            return () => {
                //Happens when component is dismounted, so cancel request
                themeAndInformationRequest.current?.cancel();
            }
        }
    }, [themeAndInformationData, requestError])

    if(requestError) return <NoMatch/>;

    return (
        <> 
            <Helmet>
                <title>Lustrum Albertus Magnus &mdash; {themeAndInformationData != null ? themeAndInformationData.title : 'Thema & informatie'}</title>
                <link rel="canonical" href={"https://www.lustrumalbertus.nl/thema-en-informatie/" + (themeAndInformationData != null ? themeAndInformationData.slug : '') }/>
            </Helmet>

            <Header heading={themeAndInformationData != null ? themeAndInformationData.title : ''}>
                <Decoration src={themeAndInformationDecoration1} bottom="0" left="0" />
                <Decoration src={themeAndInformationDecoration2} top="0" right="0" />
                <Decoration src={themeAndInformationDecoration3} bottom="0" right="0" />
            </Header>

            <Wrapper>
                {themeAndInformationData && !requestError 
                    ? <FilledContent />
                    : <LoadIcon />
                }
            </Wrapper>
            
        </>
    );

    function FilledContent() {

        return (

            <Content>
                {themeAndInformationData.paragraphs.map(paragraph => {
                    if(paragraph.type === 'text') {
                        return(
                            <Paragraph key={paragraph.id} dangerouslySetInnerHTML={{__html: paragraph.text}} />
                        )
                    }
                    if(paragraph.type === 'image') {
                        return(
                            <Image key={paragraph.id} image={paragraph.imageUrl || placeholder}/>
                        )
                    }
                    if(paragraph.type === 'youtubeURL') {
                        return(
                            <VideoLink key={paragraph.id} link={paragraph.youtubeURL} image={paragraph.imageUrl || placeholder} />
                        )
                    }
                    return null;
                })}
            </Content>
        )
    }
}

export default ThemeAndInformation
