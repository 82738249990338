import Tilt from 'react-parallax-tilt';
import styled from 'styled-components';
import tilt from '../../assets/tilt.svg';

export const TiltContainer = styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    @media screen and (max-width: 1152px) {
        display: none;
    }
`;

const ParallaxTilt = () => {

    return (
        <TiltContainer>
            <Tilt trackOnWindow={true} tiltReverse={true}>
                <img src={tilt} alt="" />
            </Tilt>
        </TiltContainer>
    )
}

export default ParallaxTilt
