import styled from 'styled-components';
import { Container } from '../../components';

export const Header = styled.section`
    background-color: rgb(var(--color-dark-cyan));
    width: 100%;
    background-size: cover;
    background-position: center;
    padding-top: 150px;
    padding-bottom: 110px;
    position: relative;

    @media screen and (max-width: 1152px) {
        position: fixed;
        top: 0;
        left: 0;
        padding-top: 20px;
        padding-bottom: 20px;
        z-index: 1;
    }
`;

export const HeaderContainer = styled(Container)`
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    z-index: 98;
`;

export const Heading = styled.h1`
    color: rgb(var(--color-ivory));
    font-size: var(--font-size-title);
    font-family: var(--font-raleway);
    font-weight: var(--bold);
    text-shadow: 2px 2px 4px rgba(var(--color-black), var(--alpha-10));
    min-height: 4rem;
    
    @media screen and (max-width: 1152px) {
        min-height: 2.1rem;
    }
`;