import styled, { createGlobalStyle } from 'styled-components';
import { normalize } from 'styled-normalize';
import reset from 'styled-reset-advanced';

const GlobalStyle = createGlobalStyle`
    ${reset}
    ${normalize}

    * {
        box-sizing: border-box;
    }

    html {
        /* Colors */
        --color-orange: 240,136,51;
        --color-dark-cyan: 17,94,103;
        --color-cyan: 49,119,128;
        --color-white: 255,255,255;
        --color-ivory: 255,255,240;
        --color-black: 0,0,0;
        --color-background: 250,250,250;
        --color-grey: 180,180,180;
        --color-link: 77,77,77;
        
        /* Alpha channels */
        --alpha-70: 0.7;
        --alpha-50: 0.5;
        --alpha-40: 0.4;
        --alpha-30: 0.3;
        --alpha-20: 0.2;
        --alpha-10: 0.1;

        /* Breakpoints */
        // Breakpoints for navigation: min-width: 1153px, max-width: 1152px
        // 1024px
        // 768px
        // 576px

        /* Typography */
        --font-raleway: 'Raleway', sans-serif;
        --font-open-sans: 'Open Sans', sans-serif;

        /* Font sizes */
        --font-size-nav: 1.6rem;
        --font-size-nav-sub: 1.4rem; 
        --font-size-counter: 4.4rem;
        --font-size-counter-label: 1.6rem;
        --font-size-default: 1.8rem;
        --font-size-button: 1.8rem;
        --font-size-footer: 1.8rem;
        --font-size-title: 4rem;
        --font-size-tablink: 1.4rem;
        --font-size-heading: 2.8rem;
        --font-size-small: 1.4rem;
        --font-size-form: 1.6rem;
        
        @media screen and (max-width: 1152px) {
            --font-size-nav: 2rem;
            --font-size-nav-sub: 1.6rem; 
            --font-size-title: 2.1rem;
        }
        
        @media screen and (max-width: 768px) {
            --font-size-counter: 4rem;
            --font-size-counter-label: 1.2rem;
            --font-size-default: 1.6rem;
            --font-size-footer: 1rem;
            --font-size-heading: 1.6rem;
            --font-size-small: 1.2rem;
            --font-size-form: 1.4rem;
        }

        /* Font weights */
        --regular: 400;
        --medium: 500;
        --semi-bold: 600;
        --bold: 700;

        /* Utils */
        --padding-outside: 40px;

        @media screen and (max-width: 768px) {
            --padding-outside: 20px;
        }

        @media screen and (max-width: 425px) {
            --padding-outside: 10px;
        }

    }

    html, body {
        margin: 0;
        padding: 0;
        font-family: 'Open Sans', sans-serif;
        font-size: 62.5%; //1 rem = 10 px;
        background-color: rgb(var(--color-background));
    }
`;

export const Container = styled.div`
    z-index: 1;
    max-width: 1400px;
    margin: 0 auto;
    padding-left: 40px;
    padding-right: 40px;

    @media screen and (max-width: 768px) {
        padding-left: var(--padding-outside);
        padding-right: var(--padding-outside);
    }
`;

export default GlobalStyle;