import styled from 'styled-components';
import { Link } from "react-router-dom";

const Button = styled(Link)`
    background-color: ${props => props.secondary ? 'rgb(var(--color-dark-cyan))' : 'rgb(var(--color-orange))'};
    padding: 15px;
    border-radius: 3px;
    cursor: pointer;
    font-family: var(--font-raleway);
    font-size: var(--font-size-button);
    text-align: center;
    font-weight: var(--bold);
    line-height: 1.25;
    min-width: 282px;
    display: inline-block;
    color: ${props => props.secondary ? 'rgb(var(--color-ivory))' : 'rgb(var(--color-white))'};
    box-shadow: ${props => props.boxshadow ? '0px 4px 8px 0px rgba(var(--color-black), var(--alpha-20))' : 'none'};
    margin-right: ${props => props.margin ? '20px' : '0'};
    margin-top: ${props => props.mt ? '20px' : '0'};
    transition: .2s transform ease;

    &:hover {
        transform: scale(1.02);
    }

    @media screen and (max-width: 768px) {
        margin-right: ${props => props.margin ? '0' : '0'};
        margin-bottom: ${props => props.margin ? '20px' : '0'};
    }
`;

export default Button;