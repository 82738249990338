import styled from 'styled-components';

export const StoreItem = styled.article`
    background-color: rgb(var(--color-white));
    padding: 30px;
    display: flex;
    align-items: center;

    &:first-of-type {
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
    }

    &:last-of-type {
        border-bottom-left-radius: 5px;
        border-bottom-right-radius: 5px;
    }

    &:not(:last-of-type) {
        border-bottom: 1px solid rgba(var(--color-grey), var(--alpha-30));
    }

    @media screen and (max-width: 768px) {
        padding: 20px;
    }

    @media screen and (max-width: 425px) {
        padding: 20px 10px;
    }
`;

export const Figure = styled.figure`
    display: ${({ mobileImg }) => (mobileImg ? 'none' : 'block')};
    border-radius: 3px;
`;

export const Img = styled.img`
    margin-right: 50px;
    width: 250px;
    height: 250px;
    object-fit: cover;
    border-radius: 3px;
    
    @media screen and (max-width: 768px) {
        margin-right: 15px;
        width: 75px;
        height: 75px;
    }
`;

export const Content = styled.section`
    flex: 1;
`;

export const Header = styled.header`
    /* border-left: 3px solid rgb(var(--color-dark-cyan)); */
    padding-left: 20px;
    align-self: center;
    position: relative;

    &:before {
        position: absolute;
        top: 0;
        left: 0;
        content: '';
        border-radius: 3px;
        width: 3px;
        height: 100%;
        background-color: rgb(var(--color-orange));
    }
`;

export const Flex = styled.div`
    display: flex;
`;

export const InfoText = styled.p`
    color: rgb(var(--color-black));
    font-size: var(--font-size-small);
    line-height: 1.25;
    margin-bottom: 5px;

    svg {
        color: rgb(var(--color-dark-cyan));
        font-size: 2rem; 
        vertical-align: middle;
    }
`;