/**
 * @property {string} id
 * @property {string} title
 * @property {string} slug
 * @property {string} date
 * @property {string} displayDate
 * @property {string} startTime
 * @property {string} endTime
 * @property {string} imageUrl
 * @property {string} location
 * @property {string} youtubeURL
 * @property {string} ticketURL
 * @property {string} introText
 * @property {string} text
 */

export default class DetailObject {
    constructor() {
        this.id = "";
        this.title = "";
        this.slug = "";
        this.date = "";
        this.displayDate = "";
        this.startTime = "";
        this.endTime = "";
        this.imageUrl = "";
        this.location = "";
        this.youtubeURL = "";
        this.ticketURL = "";
        this.introText = "";
        this.text = "";
    }

    /**
     * @param {JsonObject} jsonObject
     */
    parse(jsonObject) {
        this.id = jsonObject.string("id");
        this.title = jsonObject.stringDefault("title", "");
        this.slug = jsonObject.stringDefault("slug", "");
        this.date = jsonObject.stringDefault("date", "");
        this.displayDate = jsonObject.stringDefault("displayDate", "");
        this.startTime = jsonObject.stringDefault("startTime", "");
        this.endTime = jsonObject.stringDefault("endTime", "");
        this.imageUrl = jsonObject.stringDefault("imageUrl", "");
        this.location = jsonObject.stringDefault("location", "");
        this.youtubeURL = jsonObject.stringDefault("youtubeURL", "");
        this.ticketURL = jsonObject.stringDefault("ticketURL", "");
        this.introText = jsonObject.stringDefault("introText", "");
        this.text = jsonObject.stringDefault("text", "");
    }

    /**
     * @param {JsonObject} jsonObject
     * @return {DetailObject}
     */
    static parse(jsonObject) {
        const detailObject = new DetailObject();
        detailObject.parse(jsonObject);
        return detailObject;
    }
}