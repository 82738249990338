import React, { useEffect, useState, useRef } from 'react';
import * as S from './Footer.elements';
import { AiFillFacebook, AiFillTwitterSquare, AiFillLinkedin, AiFillInstagram } from 'react-icons/ai';
import { ContactRequest } from "../../pages/Contact/ContactRequest";

const Footer = () => {

    const contactRequest = useRef(null);
    const [contactData, setContactData] = useState(/** @var {ContactObject|null} */ null);
    const [requestError, setRequestError] = useState(null);

    useEffect(() => {
        if(!contactData && !requestError) {
            contactRequest.current?.cancel();
            contactRequest.current = new ContactRequest();
            contactRequest.current.onSuccess = (contactObject) => {
                setContactData(contactObject);
            }
            contactRequest.current.onFailure = (error) => {
                setRequestError(true);
            }
            contactRequest.current.enqueue();
            
            return () => {
                //Happens when component is dismounted, so cancel request
                contactRequest.current?.cancel();
            }
        }
    }, [contactData, requestError])

    return (
        <>
            <S.Footer>
                <S.FooterContainer>
                    <div>
                        {contactData && !requestError
                            ? <FilledContent />
                            : <FallbackLinks />
                        }
                    </div>
                    <S.CopyrightNotice>Copyright &copy; {new Date().getFullYear()} &mdash; Albertus Magnus</S.CopyrightNotice>
                </S.FooterContainer>
            </S.Footer>
        </>
    )

    function FilledContent() {
        return (
            <>
                <S.SocialMediaLink title="Facebook" href={contactData.contactFacebook} rel="noreferrer" target="_blank"><AiFillFacebook/></S.SocialMediaLink>
                <S.SocialMediaLink title="Twitter" href={contactData.contactTwitter} rel="noreferrer" target="_blank"><AiFillTwitterSquare/></S.SocialMediaLink>
                <S.SocialMediaLink title="LinkedIn" href={contactData.contactLinkedin} rel="noreferrer" target="_blank"><AiFillLinkedin/></S.SocialMediaLink>
                <S.SocialMediaLink title="Instagram" href={contactData.contactInstagram} rel="noreferrer" target="_blank"><AiFillInstagram/></S.SocialMediaLink>
            </>
        )
    }

    function FallbackLinks() {
        return (
            <>
                <S.SocialMediaLink title="Facebook" href="https://www.facebook.com/RKSVAlbertus" rel="noreferrer" target="_blank"><AiFillFacebook/></S.SocialMediaLink>
                <S.SocialMediaLink title="Twitter" href="https://twitter.com/RKSVAlbertus" rel="noreferrer" target="_blank"><AiFillTwitterSquare/></S.SocialMediaLink>
                <S.SocialMediaLink title="LinkedIn" href="https://www.linkedin.com/groups?gid=933247&mostPopular=&trk=tyah" rel="noreferrer" target="_blank"><AiFillLinkedin/></S.SocialMediaLink>
                <S.SocialMediaLink title="Instagram" href="https://www.instagram.com/rksvalbertus" rel="noreferrer" target="_blank"><AiFillInstagram/></S.SocialMediaLink>
            </>
        )
    }
}

export default Footer
