/**
 * @property {string} id
 * @property {string} title
 * @property {string} slug
 * @property {string} date
 * @property {string} imageUrl
 */

export default class PhotoAlbumsArray {
    constructor() {
        this.id = "";
        this.title = "";
        this.date = "";
    }

    /**
     * @param {JsonObject} jsonObject
     */
    parse(jsonObject) {
        this.id = jsonObject.string("id");
        this.title = jsonObject.stringDefault("title", "");
        this.slug = jsonObject.stringDefault("slug", "");
        this.date = jsonObject.stringDefault("date", "");
        this.imageUrl = jsonObject.stringDefault("imageUrl", "");
    }

    /**
     * @param {JsonObject} jsonObject
     * @return {PhotoAlbumsArray}
     */
    static parse(jsonObject) {
        const photoAlbumsArray = new PhotoAlbumsArray();
        photoAlbumsArray.parse(jsonObject);
        return photoAlbumsArray;
    }
}