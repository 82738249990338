/**
 * @property {string} id
 * @property {string} screen
 * @property {string} type
 * @property {string} description
 * @property {string} key
 * @property {string} value
 */

export default class HomeObject {
    constructor() {
        this.id = "";
        this.screen = "";
        this.type = "";
        this.description = "";
        this.key = "";
        this.value = "";
    }

    /**
     * @param {JsonObject} jsonObject
     */
    parse(jsonObject) {
        this.id = jsonObject.string("id");
        this.screen = jsonObject.stringDefault("screen", "");
        this.type = jsonObject.stringDefault("type", "");
        this.description = jsonObject.stringDefault("description", "");
        this.key = jsonObject.stringDefault("key", "");
        this.value = jsonObject.stringDefault("value", "");
    }

    /**
     * @param {JsonObject} jsonObject
     * @return {HomeObject}
     */
    static parse(jsonObject) {
        const homeObject = new HomeObject();
        homeObject.parse(jsonObject);
        return homeObject;
    }
}