import styled from 'styled-components';

const Decoration = styled.img`
    display: block;
    position: absolute;
    top: ${props => props.top};
    right: ${props => props.right};
    bottom: ${props => props.bottom};
    left: ${props => props.left};
    height: ${props => props.height ? '100%' : 'auto'};

    @media screen and (max-width: 1152px) {
        display: none;
    }
`;

export default Decoration;