import React, { useEffect, useState, useRef } from 'react';
import { TerrainRequest } from "./TerrainRequest";
import * as S from './Terrain.elements';
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import fallbackMap from '../../assets/map.svg';
import { Helmet } from 'react-helmet-async';
import { Header, Decoration, Wrapper, LoadIcon } from '../../components';
import terrainDecoration1 from '../../assets/decorations/terrain-decoration-1.svg';
import terrainDecoration2 from '../../assets/decorations/terrain-decoration-2.svg';


const Terrain = () => {

    const terrainRequest = useRef(null);
    const [terrainData, setTerrainData] = useState(/** @var {TerrainObject|null} */ null);
    const [requestError, setRequestError] = useState(null);

    useEffect(() => {
        if(!terrainData && !requestError) {
            terrainRequest.current?.cancel();
            terrainRequest.current = new TerrainRequest();
            terrainRequest.current.onSuccess = (terrainObject) => {
                setTerrainData(terrainObject);
            }
            terrainRequest.current.onFailure = (error) => {
                setRequestError(true);
            }
            terrainRequest.current.enqueue();
            
            return () => {
                //Happens when component is dismounted, so cancel request
                terrainRequest.current?.cancel();
            }
        }
    }, [terrainData, requestError])

    return (
        <> 
            <Helmet>
                <title>Lustrum Albertus Magnus &mdash; Kaart</title>
                <link rel="canonical" href="https://www.lustrumalbertus.nl/kaart"/>
            </Helmet>

            <Header heading="Kaart">
                <Decoration src={terrainDecoration1} bottom="0" left="0" />
                <Decoration src={terrainDecoration2} top="0" right="0" />
            </Header>

            <Wrapper>
                {terrainData && !requestError
                    ? <FilledContent />
                    : <LoadIcon />
                }
            </Wrapper>
            


        </>
    )

    function FilledContent() {

        return (
            <S.MapContainer>
                <TransformWrapper maxScale={3}>
                    <TransformComponent>
                        <S.Img src={terrainData.siteMap || fallbackMap} alt="Plattegrond van het Lustrum terrein" />
                    </TransformComponent>
                </TransformWrapper>
            </S.MapContainer>
        )
    }
}

export default Terrain
