import styled from 'styled-components';
import { Tab, TabList } from 'react-tabs';

export const TabNavContainer = styled.section`
    margin-top: -80px;
    background-color: rgb(var(--color-cyan));
    height: 80px;
    position: relative;

    @media screen and (max-width: 1152px) {
        margin-top: 85px;
    }
`;

export const TabNav = styled(TabList)`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
    margin: 0 auto;
    padding-left: var(--padding-outside);
    padding-right: var(--padding-outside);

    @media screen and (max-width: 1152px) {
        overflow-x: auto;
        justify-content: flex-start;

        &::-webkit-scrollbar { display: none; }
    }
`; 

export const TabLink = styled(Tab)`
    border: 1px solid rgb(var(--color-ivory));
    border-radius: 3px;
    background-color: transparent;
    font-size: var(--font-size-tablink);
    padding: 6px 20px;
    font-weight: var(--semi-bold);
    display: inline-block;
    white-space: nowrap;
    color: rgb(var(--color-ivory));
    cursor: pointer;

    &:not(:last-child) {
        margin-right: 10px;
    }

    &.is-selected {
        background-color: rgb(var(--color-ivory));
        color: rgb(var(--color-dark-cyan))
    }
`;