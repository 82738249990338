import styled from 'styled-components';

export const Label = styled.label`
    color: rgb(var(--color-black));
    font-weight: var(--semi-bold);
    font-size: var(--font-size-form);
    line-height: 1.25;

    &:after {
        content: '*';
    }
`;

export const Input = styled.input`
    margin-top: 5px;
    margin-bottom: 10px;
    display: block;
    width: ${props => props.textarea ? '100%' : '75%'};
    resize: ${props => props.textarea ? 'vertical' : ''};
    border: 1px rgba(var(--color-black), var(--alpha-10)) solid;
    padding: 12px 10px;
    font-size: var(--font-size-form);
    line-height: 1.25;
    border-radius: 5px;
    outline: none;
    transition: border .2s ease;

    &::placeholder {
        color: rgba(var(--color-black), var(--alpha-50));
        font-style: italic;
    }

    &:hover {
        border: 1px rgba(var(--color-black), var(--alpha-20)) solid;
    }

    &:focus {
        border: 1px rgb(var(--color-orange)) solid;
    }
`;

export const Error = styled.div`
    font-size: var(--font-size-form);
    color: red;
    margin-bottom: 20px;
`; 