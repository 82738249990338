import React, { useState, useEffect } from 'react'
import * as S from './Card.elements';
import placeholder from '../../assets/placeholder.svg';
import { HiOutlineChevronRight } from 'react-icons/hi';
import { MdLocationOn, MdOutlineAccessTimeFilled } from 'react-icons/md';
import { Text, Calendar } from '../../components';

const Card = (props) => {

    const [mobileHeading, setMobileHeading] = useState(false);
    const showMobileHeading = () => {
        if(window.innerWidth <= 768) {
            setMobileHeading(true);
        } else {
            setMobileHeading(false);
        }
    };

    useEffect(() => {
        showMobileHeading();
        return () => { setMobileHeading({}); }
    }, []);

    window.addEventListener('resize', showMobileHeading);

    return (
        <S.Card to={props.link || "#"}>
            <S.Figure>
                <S.Img src={props.image || placeholder} loading="lazy" alt={props.alt || "Afbeelding van Lustrum Albertus Magnus"}/>
            </S.Figure>

            <S.Content>

                <S.Header>
                    <div>
                        <S.CardHeading as="h3" mobileHeading={mobileHeading}>{props.heading}</S.CardHeading>
                        <S.Flex>
                            <Calendar month={props.month} day={props.day}/>
                            <ul>
                                <S.CardHeading mobileHeading={!mobileHeading}>{props.heading}</S.CardHeading>
                                <Text small="true" as="li"><MdOutlineAccessTimeFilled />{props.date}</Text>
                                {props.address && <Text small="true" as="li"><MdLocationOn />{props.address}</Text>}
                            </ul>
                        </S.Flex>
                    </div>

                    <S.BackBtn>
                        <HiOutlineChevronRight />
                    </S.BackBtn>
                </S.Header>

                <S.CardText truncate="true">{props.text}</S.CardText>
            </S.Content>
        </S.Card>
    )
}

export default Card
