import React, { useState, useEffect } from 'react'
import { useLocation } from "react-router-dom";
import logo from '../../assets/logo.svg';
import menuDecoration1 from '../../assets/decorations/menu-decoration-1.svg';
import menuDecoration2 from '../../assets/decorations/menu-decoration-2.svg';

import * as S from './TopNav.elements';
import { GoTriangleDown } from "react-icons/go";
import useDropdownMenu from 'react-accessible-dropdown-menu-hook';

const TopNav = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [isAccordionOpen, setIsAccordionOpen] = useState(false);
    const handleClick = () => setIsOpen(!isOpen);
    const closeMenu = () => {
        setIsOpen(false);
        setIsAccordionOpen(false);
    }

    let location = useLocation();
    const showLogo = location.pathname !== '/';
    const [offset, setOffset] = useState(0);

    useEffect(() => {
        window.onscroll = () => {
            setOffset(window.pageYOffset)
        }
    }, []);
    
    
    const [dropdown, setDropdown] = useState(true);
    const showDropdown = () => {
        if(window.innerWidth <= 1152) {
            setDropdown(false);
        } else {
            setDropdown(true);
        }
    };

    useEffect(() => {
        showDropdown();
        return () => { showDropdown({}); }
    }, []);

    window.addEventListener('resize', showDropdown);

    useEffect(() => {
        isOpen && (document.body.style.overflow = 'hidden');
        !isOpen && (document.body.style.overflow = 'unset');
    }, [isOpen ]);

    const { buttonProps, itemProps, isOpen : isDropdownOpen, setIsOpen: setIsDropdownOpen } = useDropdownMenu(7);

    return (
        <S.TopNav offset={offset}>
            <S.TopNavContainer>
                {showLogo 
                    ? <S.Logo to="/" aria-label="Home"><S.Img src={logo} alt="Het logo van Albertus Magnus"/></S.Logo> 
                    : <S.EmptyNav/>
                }

                <S.TopNavList isOpen={isOpen}>
                    <S.TopNavItem>
                        <S.TopNavLink to="/" onClick={closeMenu}>Home</S.TopNavLink>
                    </S.TopNavItem>

                    <S.TopNavItem>
                        <S.TopNavLink to="/programma" onClick={closeMenu}>Programma</S.TopNavLink>
                    </S.TopNavItem>

                    <S.TopNavItem>
                        {dropdown 
                        ?
                            <>
                                <S.TopNavLink as="button" className={location.pathname.includes('thema-en-informatie') ? 'active' : ''} {...buttonProps}>Thema & informatie <GoTriangleDown /></S.TopNavLink>
                                <S.NavDropdown className={isDropdownOpen ? 'visible' : ''} role='menu'>
                                    <S.NavDropdownLink {...itemProps[0]} to="/thema-en-informatie/thema" onClick={() => setIsDropdownOpen(false)}>
                                        <S.NavDropdownItem>Thema</S.NavDropdownItem>
                                    </S.NavDropdownLink>
                                    <S.NavDropdownLink {...itemProps[1]} to="/thema-en-informatie/fotoalbums" onClick={() => setIsDropdownOpen(false)}>
                                        <S.NavDropdownItem>Fotoalbums</S.NavDropdownItem>
                                    </S.NavDropdownLink>
                                    <S.NavDropdownLink {...itemProps[2]} to="/thema-en-informatie/organisatie" onClick={() => setIsDropdownOpen(false)}>
                                        <S.NavDropdownItem>Organisatie</S.NavDropdownItem>
                                    </S.NavDropdownLink>
                                    <S.NavDropdownLink {...itemProps[3]} to="/thema-en-informatie/goed-doel" onClick={() => setIsDropdownOpen(false)}>
                                        <S.NavDropdownItem>Goed doel</S.NavDropdownItem>
                                    </S.NavDropdownLink>
                                    <S.NavDropdownLink {...itemProps[4]} to="/thema-en-informatie/duurzaamheid" onClick={() => setIsDropdownOpen(false)}>
                                        <S.NavDropdownItem>Duurzaamheid</S.NavDropdownItem>
                                    </S.NavDropdownLink>
                                    <S.NavDropdownLink {...itemProps[5]} to="/thema-en-informatie/buurtbewoners" onClick={() => setIsDropdownOpen(false)}>
                                        <S.NavDropdownItem>Buurtbewoners</S.NavDropdownItem>
                                    </S.NavDropdownLink>
                                    <S.NavDropdownLink {...itemProps[6]} to="/thema-en-informatie/bedrijven" onClick={() => setIsDropdownOpen(false)}>
                                        <S.NavDropdownItem>Bedrijven</S.NavDropdownItem>
                                    </S.NavDropdownLink>
                                </S.NavDropdown>
                            </>
                            :
                            <S.Accordion>
                                <S.AccordionTitle className={location.pathname.includes('thema-en-informatie') ? 'active' : ''} onClick={() => setIsAccordionOpen(!isAccordionOpen)}>
                                    <span>Thema & informatie</span>
                                    <GoTriangleDown className="toggle" aria-expanded={isAccordionOpen}/>
                                    {/* <GoTriangleDown className="toggle" aria-expanded={location.pathname.includes('thema-en-informatie') ? true : isAccordionOpen}/> */}
                                </S.AccordionTitle>
                                <S.AccordionContent aria-expanded={isAccordionOpen}>
                                {/* <S.AccordionContent aria-expanded={location.pathname.includes('thema-en-informatie') ? true : isAccordionOpen}> */}
                                    <S.NavAccordionLink to="/thema-en-informatie/thema" onClick={closeMenu}>Thema</S.NavAccordionLink>
                                    <S.NavAccordionLink to="/thema-en-informatie/fotoalbums" onClick={closeMenu}>Fotoalbums</S.NavAccordionLink>
                                    <S.NavAccordionLink to="/thema-en-informatie/organisatie" onClick={closeMenu}>Organisatie</S.NavAccordionLink>
                                    <S.NavAccordionLink to="/thema-en-informatie/goed-doel" onClick={closeMenu}>Goed doel</S.NavAccordionLink>
                                    <S.NavAccordionLink to="/thema-en-informatie/duurzaamheid" onClick={closeMenu}>Duurzaamheid</S.NavAccordionLink>
                                    <S.NavAccordionLink to="/thema-en-informatie/buurtbewoners" onClick={closeMenu}>Buurtbewoners</S.NavAccordionLink>
                                    <S.NavAccordionLink to="/thema-en-informatie/bedrijven" onClick={closeMenu}>Bedrijven</S.NavAccordionLink>
                                </S.AccordionContent>
                            </S.Accordion>
                        }
                    </S.TopNavItem>

                    <S.TopNavItem>
                        <S.TopNavLink to="/kaart" onClick={closeMenu}>Kaart</S.TopNavLink>
                    </S.TopNavItem>

                    <S.TopNavItem>
                        <S.TopNavLink to="/webshop" onClick={closeMenu}>Webshop</S.TopNavLink>
                    </S.TopNavItem>

                    <S.TopNavItem>
                        <S.TopNavLink to="/partners" onClick={closeMenu}>Partners</S.TopNavLink>
                    </S.TopNavItem>
                    
                    <S.TopNavItem>
                        <S.TopNavLink to="/contact" onClick={closeMenu}>Contact</S.TopNavLink>
                    </S.TopNavItem>

                    {isOpen &&
                        <>
                            <S.MenuDecoration1 src={menuDecoration1}/>
                            <S.MenuDecoration2 src={menuDecoration2}/>
                        </>
                    }
                </S.TopNavList>

                <S.EmptyNav/>

                <S.Hamburger onClick={handleClick} isOpen={isOpen} />
            </S.TopNavContainer>
        </S.TopNav>
    )
}

export default TopNav
