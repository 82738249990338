import {BaseRequest} from "../../api/BaseRequest";
import JsonObject from "../../utils/json/JsonObject";
import PhotoAlbumObject from "../../models/PhotoAlbumObject";

/**
 * @property {function|null} onSuccess
 * @property {function|null} onFailure
 */
export class PhotoAlbumRequest extends BaseRequest {
    constructor() {
        super();

        this.onSuccess = null;
        this.onFailure = null;
    }

    createRequest() {
        const slug = window.location.href.substring(window.location.href.lastIndexOf('/') + 1)

        return {
            method: 'get',
            url: '/photoalbums/'  + slug,
            headers: this.appUserAccessTokenHeaders()
        }
    }

    handleSuccess(response) {
        try {
            const jsonObject = new JsonObject(response.data);
            const photoAlbumObject = PhotoAlbumObject.parse(jsonObject);

            this.onSuccess?.(photoAlbumObject);
        } catch(error) {
            this.handleFailure(error);
        }
    }

    handleFailure(error) {
        this.onFailure?.(error);
    }
}