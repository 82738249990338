import styled from 'styled-components';

const Paragraph = styled.section`

    p, li {
        color: rgb(var(--color-black));
        font-size:var(--font-size-default);
        margin-top: 20px;
        margin-bottom: 20px;
        line-height: 1.35;
    }

    h3 {
        font-family: var(--font-raleway);
        color: rgb(var(--color-black));
        font-size: var(--font-size-heading);
        font-weight: var(--semi-bold);
        margin-bottom: 10px;
        line-height: 1.25;
    }

    a {
        text-decoration: underline;
        color: rgb(var(--color-link));
        transition: color .2s ease;

        &:hover {
            color: rgb(var(--color-black));
        }
    }

    em { font-style: italic; }

    ul, ol {
        margin-top: 20px;
        margin-bottom: 20px;
        padding-left: 30px;
    }

    ul { list-style: disc; }
    
    ol { list-style: decimal; }

    li {
        margin-top: 10px;
        margin-bottom: 10px;
    }

`;

export default Paragraph;