import Countdown from "react-countdown"; 
import * as S from './LustrumCountdown.elements';

const LustrumCountdown = (props) => {


    // completed return null
    // const CompletedCountdown = () =>  <span>Van start!</span>;

    const renderer = ({ days, hours, minutes, seconds }) => {
        // if (completed) {
        //     return <CompletedCountdown />;
        // } else {

            return (
                <S.CounterContainer>
                    <S.Counter>
                        <span>{days < 10 ? '0' : ''}{days}</span>
                        <p>{days === 1 ? 'dag' : 'dagen'}</p>
                    </S.Counter>
                    <S.Counter>
                        <span>{hours < 10 ? '0' : ''}{hours}</span>
                        <p>{hours === 1 ? 'uur' : 'uren'}</p>
                    </S.Counter>
                    <S.Counter>
                        <span>{minutes < 10 ? '0' : ''}{minutes}</span>
                        <p>{minutes === 1 ? 'minuut' : 'minuten'}</p>
                    </S.Counter>
                    <S.Counter>
                        <span>{seconds < 10 ? '0' : ''}{seconds}</span>
                        <p>{seconds === 1 ? 'seconde' : 'seconden'}</p>
                    </S.Counter>
                </S.CounterContainer>
            );
        // }
    };

    return (
        <>
            <S.LustrumCountdownContainer>
                <Countdown date={props.date} renderer={renderer} />
                {/* <Countdown date={Date.now() + 2000} renderer={renderer} /> */}
                <S.LustrumCountdownInfo>
                    {props.info}
                </S.LustrumCountdownInfo>
            </S.LustrumCountdownContainer>
        </>
    )
}

export default LustrumCountdown
