import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const BackBtn = styled(Link)`
    font-size: var(--font-size-default);
    color: rgb(var(--color-black));
    display: inline-flex;
    align-items: center;
    cursor: pointer;

    svg {
        color: rgb(var(--color-orange));
        font-size: 4rem; 
        margin-right: 10px;
        transition: .2s transform ease;
    }

    &:hover {
        svg {
            transform: translateX(-5px);
        }
    }
`;

export const Header = styled.header`
    /* border-left: 3px solid rgb(var(--color-orange)); */
    padding-left: 20px;
    position: relative;

    &:before {
        position: absolute;
        top: 0;
        left: 0;
        content: '';
        border-radius: 3px;
        width: 3px;
        height: 100%;
        background-color: rgb(var(--color-orange));
    }
`;

export const Flex = styled.div`
    display: flex;
    margin-top: 10px;
`;

export const Figure = styled.figure`
    width: 100%;
    height: auto;
    margin-top: 20px;
`;

export const Img = styled.img`
    width: 100%;
    height: auto;
    object-fit: cover;
`;