import styled, { css } from 'styled-components';

export const Title = styled.h1`
    font-family: var(--font-raleway);
    color: ${props => props.sub ? 'rgb(var(--color-dark-cyan))' : 'rgb(var(--color-ivory))'};
    font-size: var(--font-size-title);
    font-weight: var(--bold);
    text-shadow: ${props => props.sub ? '' : '2px 2px 4px rgba(var(--color-black), var(--alpha-10))'};
    margin-bottom: 10px;
    
    ${props => props.margin ? `${margin}` : ''};
    ${props => props.header ? `${header}` : ''};
    
    @media screen and (max-width: 1152px) {
        min-height: 2.1rem;
    }
`;

const margin = css`
    margin-bottom: 40px;

    @media screen and (max-width: 1152px) {
        margin-bottom: 20px;
    }
`;

const header = css`
    min-height: 4rem;
`;

export default Title;