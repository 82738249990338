import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { Container } from '../../components';

export const PhotoGridContainer = styled(Container)`
    padding-bottom: 120px;
    margin-top: ${props => props.detail ? '40px' : '80px'};

    @media screen and (max-width: 1152px) {
        margin-top: ${props => props.detail ? '100px' : '120px'};
    }
`;

export const Grid = styled.section`
    /* margin-top: ${props => props.detail ? '40px' : '80px'}; */
    /* margin-top: 40px; */
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 20px;

    /* @media screen and (max-width: 1152px) {
        margin-top: 20px;
    } */

    @media screen and (max-width: 1024px) {
        grid-template-columns: repeat(3, 1fr);
    }

    @media screen and (max-width: 768px) {
        grid-template-columns: repeat(2, 1fr);
    }

    @media screen and (max-width: 320px) {
        grid-template-columns: repeat(1, 1fr);
    }
`;

export const BackBtn = styled(Link)`
    margin-bottom: 40px;
    font-size: var(--font-size-default);
    color: rgb(var(--color-black));
    display: inline-flex;
    align-items: center;
    cursor: pointer;

    svg {
        color: rgb(var(--color-orange));
        font-size: 4rem; 
        margin-right: 10px;
        transition: .2s transform ease;
    }

    &:hover {
        svg {
            transform: translateX(-5px);
        }
    }

    @media screen and (max-width: 1152px) {
        margin-bottom: 20px;
    }
`;