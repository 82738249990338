import * as S from './Header.elements';
import { Title } from '../../components';

const Header = (props) => {

    return (
        <>
            <S.Header>
                <S.HeaderContainer>
                    <Title header="true">{props.heading}</Title>
                </S.HeaderContainer>
                {props.children}
            </S.Header>
        </>
    )
}

export default Header
