import React, { useState, useEffect } from 'react'
import * as S from './StoreItem.elements';
import placeholder from '../../assets/placeholder.svg';
import { Button, Text, Heading } from '../../components';

const StoreItem = (props) => {

    const [mobileImg, setMobileImg] = useState(false);
    const showMobileImg = () => {
        if(window.innerWidth <= 768) {
            setMobileImg(true);
        } else {
            setMobileImg(false);
        }
    };

    useEffect(() => {
        showMobileImg();
        return () => { setMobileImg({}); }
    }, []);

    window.addEventListener('resize', showMobileImg);

    return (
        <S.StoreItem>
            <S.Figure mobileImg={mobileImg}>
                <S.Img src={props.imageUrl || placeholder} loading="lazy" alt={props.alt || ""}/>
            </S.Figure>

            <S.Content>

            <S.Flex>
                <S.Figure mobileImg={!mobileImg}>
                    <S.Img src={props.imageUrl || placeholder} loading="lazy" alt={props.alt || ""}/>
                </S.Figure>
                <S.Header>
                    <Heading marginbottom="true">{props.title || "Title"}</Heading>
                    <Text small="true">&euro;{props.price || "00,00"}</Text>
                    {/* <S.InfoText>&euro;{props.price || "00,00"}</S.InfoText> */}
                </S.Header>
            </S.Flex>

                <Text>{props.text || "Text"}</Text>
                <Button as="a" href={props.orderURL || '#'} rel="noreferrer" target="_blank">Bestellen</Button>
            </S.Content>
        </S.StoreItem>
    )
}

export default StoreItem
