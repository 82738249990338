import * as S from './CardList.elements';

const CardList = (props) => {

    return (
        <S.CardListContainer>
            <S.ListHeading>{props.heading}</S.ListHeading>
            <S.CardList>
                {props.children}
            </S.CardList>
        </S.CardListContainer>
    )
}

export default CardList
