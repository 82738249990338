import styled from 'styled-components';

export const Figure = styled.figure`
    position: relative;
    width: 100%;
    padding-top: 100%;
    overflow: hidden;
`;

export const Image = styled.img`
    position:  absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
    max-height: 100%;
    object-fit: cover;
    transition: transform .2s ease;
`;

export const Item = styled.div`
    height: 100%;
    box-shadow: 0px 2px 10px 0px rgba(var(--color-black), var(--alpha-10));
    border-radius: 5px;
    background-color: rgb(var(--color-white));
    overflow: hidden;
    cursor: pointer;

    &:hover ${Image} {
        transform: scale(1.05);
    }
`;

export const Body = styled.section`
    padding: 20px;
`;

export const Title = styled.h3`
    font-family: var(--font-raleway);
    color: rgb(var(--color-black));
    font-size: var(--font-size-heading);
    font-weight: var(--semi-bold);
    margin-bottom: 10px;
    line-height: 1.25;
    word-break: break-word;
    /* Max 2 lines */
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
`;