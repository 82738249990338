import styled from 'styled-components';
import Loader from 'react-loader-spinner';

export const LoadContainer = styled.div`
    padding-top: 80px;
    display: flex;
    align-items: center;
    justify-content: center;

    @media screen and (max-width: 1152px) {
        padding-top: 160px;
    }
`;

const LoadIcon = () => {

    return (
        <LoadContainer>
            <Loader
                type="ThreeDots"
                color="#f08833"
                height={75}
                width={75}
            />
        </LoadContainer>
    )
}

export default LoadIcon