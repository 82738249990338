import styled from 'styled-components';
import { Image } from '../../components';
import playbtn from '../../assets/playbtn.svg';

export const StyledVideoLink = styled.a`
`;

export const PlayBtn = styled.img`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
`;

const VideoLink = (props) => {

    return (
        <StyledVideoLink href={props.link} rel="noreferrer" target="_blank">
            <Image image={props.image} alt={props.alt || ''}>
                <PlayBtn src={playbtn} alt="" />
            </Image>
        </StyledVideoLink>
    )
}

export default VideoLink
