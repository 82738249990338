import {BaseRequest} from "../../api/BaseRequest";
import JsonObject from "../../utils/json/JsonObject";
import JsonArray from "../../utils/json/JsonArray";
import ProgramsArray from "../../models/ProgramsArray";

/**
 * @property {function|null} onSuccess
 * @property {function|null} onFailure
 */
export class ProgramsRequest extends BaseRequest {
    constructor() {
        super();

        this.onSuccess = null;
        this.onFailure = null;
    }

    createRequest() {
        return {
            method: 'get',
            url: '/programs',
            headers: this.appUserAccessTokenHeaders()
        }
    }

    handleSuccess(response) {
        try {
            const jsonArray = new JsonArray(response.data);

            let programs = [];
            jsonArray.jsonData.forEach((programsJsonData) => {
                const programJsonObject = new JsonObject(programsJsonData);
                programs.push(ProgramsArray.parse(programJsonObject));
            });
            let allPrograms = ProgramsArray.allPrograms(programs);
            programs.unshift(allPrograms);

            const programsDataWithMonths = programs.map((item) => {
                let itemsGroupedByMonth = [];
                item.items.forEach((item) => {
                    const date = new Date(item.date * 1000);
                    const month = date.toLocaleString('default', { month: 'long' });
                    if (!itemsGroupedByMonth[month]) {
                        itemsGroupedByMonth[month] = [];
                    }
                    const groupedItem = {
                        ...item,
                        month,
                    };
                    itemsGroupedByMonth[month].push(groupedItem);
                });
                return {
                    ...item,
                    perMonth: Object.entries(itemsGroupedByMonth).map(([month, items]) => ({
                        month,
                        items,
                    }))
                };
            });

            this.onSuccess?.(programsDataWithMonths);
        } catch(error) {
            this.handleFailure(error);
        }
    }

    handleFailure(error) {
        this.onFailure?.(error);
    }
}