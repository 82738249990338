import Api from "./Api";
import axios from "axios";

export class BaseRequest {
    constructor() {
        this.cancelSource = axios.CancelToken.source();
    }

    /**
     * @return AxiosRequestConfig
     */
    createRequest() {
        //Override in child
    }

    /**
     * @return {Object}
     */
    appUserHeaders() {
        return {
            'App-User': 'AAAA-BBBB-CCCC-DDDD-EEEE'
        }
    }

    /**
     * @return {Object}
     */
    appUserAccessTokenHeaders() {
        const headers = this.appUserHeaders();
        return headers;
    }

    enqueue() {
        const thisRequest = this;
        const requestConfig = this.createRequest();
        if(!requestConfig.cancelToken) {
            requestConfig.cancelToken = this.cancelSource.token;
        }
        Api.manager.request(requestConfig)
            .then(function (response) {
                try {
                    thisRequest.handleSuccess(response);
                } catch (error) {
                    thisRequest.handleFailure(error);
                }
            })
            .catch(function (error) {
                if(axios.isCancel(error)) {
                    thisRequest.handleCancelled(error)
                } else {
                    if(error.response?.status === 401) {
                        thisRequest.handleUnauthorized(error);
                    } else {
                        thisRequest.handleFailure(error);
                    }
                }
            })
    }

    /**
     * @param {AxiosResponse<any>} response
     */
    handleSuccess(response) {
        //Override in child
    }

    /**
     * @param {AxiosError<any>} error
     */
    handleFailure(error) {
        //Override in child
    }

    handleCancelled(error) {
        //Override in child
    }

    /**
     * @param {AxiosError<any>} error
     */
    handleUnauthorized(error) {
        // Override in child
    }

    cancel() {
        this.cancelSource.cancel("Cancel method called");
    }
}