import React, { useEffect, useState, useRef } from 'react';
import { Background, Container, LustrumCountdown, Logo, ButtonWrapper, Button, ParallaxTilt, LoadIcon } from '../../components';
import { HomeRequest } from "./HomeRequest";
import { Helmet } from 'react-helmet-async';
import backgroundImage from '../../assets/home-background.jpg';
import logo from '../../assets/logo.svg';
import styled from 'styled-components';

export const HomeContainer = styled(Container)`
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 100%;
    z-index: 1;
`;

const Home = () => {

    const homeRequest = useRef(null);
    const [homeData, setHomeData] = useState(/** @var {HomeObject|null} */ null);
    const [requestError, setRequestError] = useState(null);

    useEffect(() => {
        if(!homeData && !requestError) {
            homeRequest.current?.cancel();
            homeRequest.current = new HomeRequest();
            homeRequest.current.onSuccess = (homeObject) => {
                setHomeData(homeObject);
            }
            homeRequest.current.onFailure = (error) => {
                setRequestError(true);
            }
            homeRequest.current.enqueue();
            
            return () => {
                //Happens when component is dismounted, so cancel request
                homeRequest.current?.cancel();
            }
        }
    }, [homeData, requestError])

    return (
        <>
            <Helmet>
                <title>Lustrum Albertus Magnus &mdash; Home</title>
                <link rel="canonical" href="https://www.lustrumalbertus.nl/home"/>
            </Helmet>
            <Background img={backgroundImage}>
                <HomeContainer>
                    
                    {homeData && !requestError
                        ? <FilledContent />
                        : <LoadIcon/>
                    }

                </HomeContainer>
                <ParallaxTilt />
            </Background>
        </>
    )
    
    function FilledContent() {
    
        return (
            <>
                <Logo src={logo} alt="Logo van Lustrum Albertus Magnus" draggable={false}/>
                <Countdown />
                <ButtonWrapper marginbottom="true">
                    <Button boxshadow="true" margin="true" as="a" href={homeData.lustrumDeeplink} rel="noreferrer" target="_blank">Download de app hier</Button>
                    <Button boxshadow="true" secondary="true" to="/programma">Bekijk het programma</Button>
                </ButtonWrapper>
    
            </>
        )
    }
    
    function Countdown() {
        let today = new Date();
        const date = new Date(homeData.lustrumStartdate + 'T' + homeData.lustrumStarttime);
        
        if (today < date) {
            return (
                <LustrumCountdown date={date} info={homeData.lustrumClockSubtitle}/> 
            )
        } else {
            return null;
        }
    }
}


export default Home
