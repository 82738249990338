import styled from 'styled-components';

export const StyledCalendar = styled.div`
    background-color: rgb(var(--color-dark-cyan));
    border-radius: 4px;
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    flex-shrink: 0;
    justify-content: center;
    flex-direction: column;
    margin-right: 20px;
    position: relative;

    &:before {
        position: absolute;
        top: -5px;
        left: 10px;
        content: '';
        width: 5px;
        height: 10px;
        background-color: rgb(var(--color-dark-cyan));
        border: 1px solid rgb(var(--color-white));
    }

    &:after {
        position: absolute;
        top: -5px;
        right: 10px;
        content: '';
        width: 5px;
        height: 10px;
        background-color: rgb(var(--color-dark-cyan));
        border: 1px solid rgb(var(--color-white));
    }

    span {
        color: rgb(var(--color-white));

        &:nth-of-type(1) {
            font-size: 1rem;
            text-transform: uppercase;
        }

        &:nth-of-type(2) {
            font-family: var(--font-raleway);
            font-size: 2rem;
            font-weight: var(--bold);
        }
    }
`;

const Calendar = (props) => {

    return (
        <StyledCalendar>
            <span>{props.month || "MND"}</span>
            <span>{props.day || "DD"}</span>
        </StyledCalendar>
    )
}

export default Calendar
