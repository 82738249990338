import styled from 'styled-components';
import { Container } from '../../components';

export const GridContainer = styled(Container)`
    max-width: 920px;
    position: relative;
    text-align: center;
    margin-bottom: 80px;
    
    @media screen and (max-width: 1152px) {
        margin-bottom: 40px;
    }


    &:first-of-type {
        padding-top: 120px;
    }

    &:last-of-type {
        margin-bottom: 100px; 

        @media screen and (max-width: 768px) {
            margin-bottom: 40px;
        }
    }
`;

export const Grid = styled.section`
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;

    @media screen and (max-width: 576px) {
        grid-template-columns: 1fr;
    }
`;