import styled from 'styled-components';
import { Container } from '../../components';

export const Footer = styled.footer`
    background-color: rgb(var(--color-dark-cyan));
    padding-top: 10px;
    padding-bottom: 10px;
    margin-top: auto;
    z-index: 1;
`;

export const FooterContainer = styled(Container)`
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

export const SocialMediaLink = styled.a`
    color: rgb(var(--color-white));
    font-size: 3rem;
    margin-right: 5px;

    @media screen and (max-width: 768px) {
        font-size: 2.5rem;
    }
`;

export const CopyrightNotice = styled.p`
    font-size: var(--font-size-footer);
    color: rgb(var(--color-white));
    line-height: 1.25;
`;
