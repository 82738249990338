import styled from 'styled-components';

export const LustrumCountdownContainer = styled.section`
    margin-top: 20px;
    margin-bottom: 20px;
    background-color: rgb(var(--color-ivory));
    border-radius: 5px;
    display: ${({hidden}) => hidden ? 'none' : 'inline-flex'};
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;
    box-shadow: 0px 4px 8px 0px rgba(var(--color-black), var(--alpha-20));

    @media screen and (max-width: 360px) {
        width: 100%;
    }
`;

export const CounterContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const Counter = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100px;
    position: relative;

    span {
        font-family: var(--font-raleway);
        font-size: var(--font-size-counter);
        color: rgb(var(--color-dark-cyan));
        font-weight: var(--bold);
        margin-bottom: 10px;
    }

    p {
        color: rgb(var(--color-grey));
        font-size: var(--font-size-counter-label);
    }

    &:not(:last-child):after {
        position: absolute;
        content:'';
        right: 0;
        width: 2px;
        height: 40px;
        background-color: rgba(var(--color-orange), var(--alpha-20));
    }

    @media screen and (max-width: 768px) {
        width: 75px;
    }
`;

export const LustrumCountdownInfo = styled.p`
    margin-top: 20px;
    font-size: var(--font-size-default);
    line-height: 1.25;
    color: rgb(--color-black);
`;