/**
 * @property {string} id
 * @property {string} title
 * @property {string} price
 * @property {string} text
 * @property {string} orderURL
 * @property {string} imageUrl
 */
export default class WebshopItem {
    constructor() {
        this.id = "";
        this.title = "";
        this.price = "";
        this.text = "";
        this.orderURL = "";
        this.imageUrl = "";
    }

    /**
     * @param {JsonObject} jsonObject
     */
    parse(jsonObject) {
        this.id = jsonObject.string("id");
        this.title = jsonObject.stringDefault("title", "");
        this.price = jsonObject.stringDefault("price", "");
        this.text = jsonObject.stringDefault("text", "");
        this.orderURL = jsonObject.stringDefault("orderURL", "");
        this.imageUrl = jsonObject.stringDefault("imageUrl", "");
    }

    /**
     * @param {JsonObject} jsonObject
     * @return {WebshopItem}
     */
    static parse(jsonObject) {
        const webshopItem = new WebshopItem();
        webshopItem.parse(jsonObject);
        return webshopItem;
    }
}