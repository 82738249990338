/**
 * @property {string} id
 * @property {string} title
 * @property {string} slug
 * @property {Array.<ProgramsItems>} items
 */
import JsonObject from "../utils/json/JsonObject";

export default class ProgramsArray {
    constructor() {
        this.id = "";
        this.title = "";
        this.slug = "";
        this.items = [];
    }

    /**
     * @param {JsonObject} jsonObject
     */
    parse(jsonObject) {
        this.id = jsonObject.string("id");
        this.title = jsonObject.stringDefault("title", "");
        this.slug = jsonObject.stringDefault("slug", "");

        const itemsJsonArray = jsonObject.array("items");
        this.items = [];
        itemsJsonArray.jsonData.forEach((itemsJsonData) => {
            const itemsJsonObject = new JsonObject(itemsJsonData);
            this.items.push(ProgramsItem.parse(itemsJsonObject))
        })
    }

    /**
     * @param {JsonObject} jsonObject
     * @return {ProgramsArray}
     */
    static parse(jsonObject) {
        const programsArray = new ProgramsArray();
        programsArray.parse(jsonObject);
        return programsArray;
    }

    /**
     * 
     * @param {Array.<ProgramArray>} allProgramsArrays 
     * @return {ProgramsArray}
     */
    static allPrograms(allProgramsArrays) {
        const programsArray = new ProgramsArray();
        programsArray.id = "lustrum-program-category-all";
        programsArray.title = "Alle";
        programsArray.slug = "all";
        programsArray.items = allProgramsArrays.flatMap((item) => item.items).sort((item1, item2) => {
            if(item1.date < item2.date) {
                return -1;
            } else if(item1.date > item2.date) {
                return 1;
            } else {
                if(item1.startTime < item2.startTime) {
                    return -1;
                } else if(item1.startTime > item2.startTime) {
                    return 1;
                } else {
                    return 0;
                }
            }
        })
        return programsArray;
    }
}

/**
 * @property {string} id
 * @property {string} title
 * @property {string} slug
 * @property {string} date
 * @property {string} displayDate
 * @property {string} startTime
 * @property {string} endTime
 * @property {string} imageUrl
 * @property {string} location
 * @property {string} youtubeURL
 * @property {string} ticketURL
 * @property {string} introText
 * @property {string} text
 */
export class ProgramsItem {
    constructor() {
        this.id = "";
        this.title = "";
        this.slug = "";
        this.date = "";
        this.displayDate = "";
        this.startTime = "";
        this.endTime = "";
        this.imageUrl = "";
        this.location = "";
        this.youtubeURL = "";
        this.ticketURL = "";
        this.introText = "";
        this.text = "";
    }

    /**
     * @param {JsonObject} jsonObject
     */
    parse(jsonObject) {
        this.id = jsonObject.string("id");
        this.title = jsonObject.stringDefault("title", "");
        this.slug = jsonObject.stringDefault("slug", "");
        this.date = jsonObject.stringDefault("date", "");
        this.displayDate = jsonObject.stringDefault("displayDate", "");
        this.startTime = jsonObject.stringDefault("startTime", "");
        this.endTime = jsonObject.stringDefault("endTime", "");
        this.imageUrl = jsonObject.stringDefault("imageUrl", "");
        this.location = jsonObject.stringDefault("location", "");
        this.youtubeURL = jsonObject.stringDefault("youtubeURL", "");
        this.ticketURL = jsonObject.stringDefault("ticketURL", "");
        this.introText = jsonObject.stringDefault("introText", "");
        this.text = jsonObject.stringDefault("text", "");
    }

    /**
     * @param {JsonObject} jsonObject
     * @return {ProgramsItem}
     */
    static parse(jsonObject) {
        const programsItem = new ProgramsItem();
        programsItem.parse(jsonObject);
        return programsItem;
    }
}