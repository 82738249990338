import React, { useEffect, useState, useRef } from 'react';
import * as S from './Webshop.elements';
import { WebshopRequest } from "./WebshopRequest";
import { Helmet } from 'react-helmet-async';
import { Header, Decoration, StoreItem, LoadIcon, Wrapper } from '../../components';
import webshopDecoration1 from '../../assets/decorations/webshop-decoration-1.svg';
import webshopDecoration2 from '../../assets/decorations/webshop-decoration-2.svg';

const Webshop = () => {

    const webshopRequest = useRef(null);
    const [webshopData, setWebshopData] = useState(/** @var {Array.<WebshopItem>|null} */ null);
    const [requestError, setRequestError] = useState(null);

    useEffect(() => {
        if(!webshopData && !requestError) {
            webshopRequest.current?.cancel();
            webshopRequest.current = new WebshopRequest();
            webshopRequest.current.onSuccess = (webshopItems) => {
                setWebshopData(webshopItems);
            }
            webshopRequest.current.onFailure = (error) => {
                setRequestError(true);
            }
            webshopRequest.current.enqueue();
            
            return () => {
                //Happens when component is dismounted, so cancel request
                webshopRequest.current?.cancel();
            }
        }
    }, [webshopData, requestError])

    return (
        <>
            <Helmet>
                <title>Lustrum Albertus Magnus &mdash; Webshop</title>
                <link rel="canonical" href="https://www.lustrumalbertus.nl/webshop"/>
            </Helmet>

            <Header heading="Webshop">
                <Decoration src={webshopDecoration1} bottom="0" left="0" />
                <Decoration src={webshopDecoration2} bottom="0" right="0" />
            </Header>

            <Wrapper>
                {webshopData && !requestError 
                    ? <FilledContent />
                    : <LoadIcon />
                }
            </Wrapper>
        </>
    );

    function FilledContent() {

        return (
                
            <S.WebshopList>
                {webshopData.map(item => {
                    return(
                        <StoreItem key={item.id} title={item.title} imageUrl={item.imageUrl} alt={`Afbeelding van ${item.title}`} price={item.price} text={item.text} orderURL={item.orderURL} />
                        )
                    })}
            </S.WebshopList>

        )
    }
}

export default Webshop
