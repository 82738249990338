import {BaseRequest} from "../../api/BaseRequest";
import JsonObject from "../../utils/json/JsonObject";
import DetailObject from "../../models/DetailObject";

/**
 * @property {function|null} onSuccess
 * @property {function|null} onFailure
 */
export class DetailRequest extends BaseRequest {
    constructor() {
        super();

        this.onSuccess = null;
        this.onFailure = null;
    }

    createRequest() {
        const slug = window.location.href.substring(window.location.href.lastIndexOf('/') + 1)

        return {
            method: 'get',
            url: '/program/' + slug,
            headers: this.appUserAccessTokenHeaders()
        }
    }

    handleSuccess(response) {
        try {
            const jsonObject = new JsonObject(response.data);
            const detailObject = DetailObject.parse(jsonObject);

            this.onSuccess?.(detailObject);
        } catch(error) {
            this.handleFailure(error);
        }
    }

    handleFailure(error) {
        this.onFailure?.(error);
    }
}