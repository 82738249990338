import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { Heading, Text } from '../../components';

export const Card = styled(Link)`
    background-color: rgb(var(--color-white));
    padding: 30px;
    display: flex;
    align-items: center;
    word-break: break-word;
    
    &:first-of-type {
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
    }

    &:last-of-type {
        border-bottom-left-radius: 5px;
        border-bottom-right-radius: 5px;
    }

    &:not(:last-of-type) {
        border-bottom: 1px solid rgba(var(--color-grey), var(--alpha-30));
    }

    @media screen and (max-width: 768px) {
        padding: 20px;
    }

    @media screen and (max-width: 425px) {
        padding: 10px;
    }
`;

export const Figure = styled.figure`
    margin-right: 50px;

    @media screen and (max-width: 768px) {
        display: none;
    }
`;

export const Img = styled.img`
    width: 250px;
    height: 250px;
    object-fit: cover;
    border-radius: 3px;
`;

export const Content = styled.section`
    flex: 1;
`;

export const Header = styled.header`
    /* border-left: 3px solid rgb(var(--color-orange)); */
    padding-left: 20px;
    display: flex;
    justify-content: space-between;

    @media screen and (min-width: 769px) {
        position: relative;
        
        &:before {
            position: absolute;
            top: 0;
            left: 0;
            content: '';
            border-radius: 3px;
            width: 3px;
            height: 100%;
            background-color: rgb(var(--color-orange));
        }
    }

    @media screen and (max-width: 768px) {
        border: none;
        padding: 0;
        align-items: center;
    }
`;

export const BackBtn = styled.div`
    svg {
        color: rgb(var(--color-orange));
        font-size: 3rem; 
        transition: .2s transform ease;

        ${Card}:hover & {
            transform: translateX(5px);
        }
    }
`;

export const Flex = styled.div`
    display: flex;
    margin-top: 10px;
`;

export const CardHeading = styled(Heading)`
    display: ${({ mobileHeading }) => (mobileHeading ? 'none' : 'block')};
`;

export const CardText = styled(Text)`
    margin-bottom: 0;

    @media screen and (max-width: 768px) {
        display: none;
    }
`;