import * as S from './PhotoGrid.elements';
import { HiOutlineChevronLeft } from 'react-icons/hi';

const PhotoGrid = ({detail, children}) => {

    return (
        <S.PhotoGridContainer detail={detail}>
            {detail &&
                <S.BackBtn to='/thema-en-informatie/fotoalbums/'>
                    <HiOutlineChevronLeft /> Terug naar fotoalbums
                </S.BackBtn>
            }

            <S.Grid>
                {children}
            </S.Grid>
        </S.PhotoGridContainer>
    )
}

export default PhotoGrid
