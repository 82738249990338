/**
 * @property {string} id
 * @property {string} title
 * @property {Array.<PartnersItems>} items
 */
import JsonObject from "../utils/json/JsonObject";

export default class PartnersArray {
    constructor() {
        this.id = "";
        this.title = "";
        this.items = [];
    }

    /**
     * @param {JsonObject} jsonObject
     */
    parse(jsonObject) {
        this.id = jsonObject.string("id");
        this.title = jsonObject.stringDefault("title", "");

        const itemsJsonArray = jsonObject.array("items");
        this.items = [];
        itemsJsonArray.jsonData.forEach((itemsJsonData) => {
            const itemsJsonObject = new JsonObject(itemsJsonData);
            this.items.push(PartnersItem.parse(itemsJsonObject))
        })
    }

    /**
     * @param {JsonObject} jsonObject
     * @return {PartnersArray}
     */
    static parse(jsonObject) {
        const partnersArray = new PartnersArray();
        partnersArray.parse(jsonObject);
        return partnersArray;
    }
}

/**
 * @property {string} id
 * @property {string} title
 * @property {string} url
 * @property {string} imageUrl
 */
export class PartnersItem {
    constructor() {
        this.id = "";
        this.title = "";
        this.url = "";
        this.imageUrl = "";
    }

    /**
     * @param {JsonObject} jsonObject
     */
    parse(jsonObject) {
        this.id = jsonObject.string("id");
        this.title = jsonObject.stringDefault("title", "");
        this.url = jsonObject.stringDefault("url", "");
        this.imageUrl = jsonObject.stringDefault("imageUrl", "");
    }

    /**
     * @param {JsonObject} jsonObject
     * @return {PartnersItem}
     */
    static parse(jsonObject) {
        const partnersItem = new PartnersItem();
        partnersItem.parse(jsonObject);
        return partnersItem;
    }
}