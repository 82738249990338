import styled, { css } from 'styled-components';

const ButtonWrapper = styled.section`
    display: flex;
    justify-content: ${props => props.justifycontent};
    align-items: ${props => props.alignitems};
    flex-direction: ${props => props.direction};
    margin-top: 20px;
    
    ${props => props.marginbottom ? `${marginbottom}` : ''};
`;

const marginbottom = css`
    margin-bottom: 100px;

    @media screen and (max-width: 768px) {
        flex-direction: column;
    }
`;

export default ButtonWrapper;