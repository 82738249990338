import React, { useEffect, useState, useRef } from 'react';
import { Header, Decoration, PhotoGrid, PhotoGridItem, LoadIcon, Wrapper } from '../../components';
import { PhotoAlbumRequest } from "./PhotoAlbumRequest";
import { Helmet } from 'react-helmet-async';
import { SRLWrapper } from "simple-react-lightbox";
import themeAndInformationDecoration1 from '../../assets/decorations/theme-and-information-decoration-1.svg';
import themeAndInformationDecoration2 from '../../assets/decorations/theme-and-information-decoration-2.svg';
import themeAndInformationDecoration3 from '../../assets/decorations/theme-and-information-decoration-3.svg';
import axios from 'axios';

const PhotoAlbum = () => {

    // PhotoAlbumObject
    const photoAlbumRequest = useRef(null);
    const [photoAlbumData, setPhotoAlbumData] = useState(/** @var {PhotoAlbumObject|null} */ null);
    const [requestError, setRequestError] = useState(null);

    useEffect(() => {
        if(!photoAlbumData && !requestError) {
            photoAlbumRequest.current?.cancel();
            photoAlbumRequest.current = new PhotoAlbumRequest();
            photoAlbumRequest.current.onSuccess = (photoAlbumObject) => {
                setPhotoAlbumData(photoAlbumObject);
            }
            photoAlbumRequest.current.onFailure = (error) => {
                setRequestError(true);
            }
            photoAlbumRequest.current.enqueue();
            
            return () => {
                //Happens when component is dismounted, so cancel request
                photoAlbumRequest.current?.cancel();
            }
        }
    }, [photoAlbumData, requestError])

    // Fetch album photos
    const [data, setData] = useState([]);
    const [prevData, setPrevData] = useState([]);
    const [amount, setAmount] = useState(20);
    const [isFetching, setIsFetching] = useState(false);

    useEffect(() => {
        getData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const slug = window.location.href.substring(window.location.href.lastIndexOf('/') + 1)

	function getData(){
		axios.get(`${process.env.REACT_APP_API_BASE_URL}/photoalbums/${slug}/albumphotos?page[number]=1&page[size]=${amount}`, {headers: {
            'App-User': 'AAAA-BBBB-CCCC-DDDD-EEEE'
        }})
        .then(response => {
            setData(response.data);
            setAmount(amount + 10); // Increase the fetch amount with 20
            setIsFetching(false); 
            setPrevData(data); // Set previous data to check when to stop with fetching
        })
        .catch((error) => {
            // Log errors
        })
	}

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);
    
    function handleScroll() {
        if (window.innerHeight + document.documentElement.scrollTop !== document.body.scrollHeight) return;
        setIsFetching(true);
    }

    useEffect(() => {
        if (!isFetching) return;
        if (data.length !== prevData.length){
            getData();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isFetching, getData]);

    // SRL Options
    const options = {
        buttons: {
            showAutoplayButton: false,
            showDownloadButton: false,
            showThumbnailsButton: true,
        },
        thumbnails: {
            showThumbnails: false,
        }
    }
    
    return (
        <> 
            <Helmet>
                <title>Lustrum Albertus Magnus &mdash; PhotoAlbum</title>
                <link rel="canonical" href="https://www.lustrumalbertus.nl/thema-en-informatie/fotoalbum"/>
            </Helmet>

            <Header heading={photoAlbumData != null ? photoAlbumData.title : ''}>
                <Decoration src={themeAndInformationDecoration1} bottom="0" left="0" />
                <Decoration src={themeAndInformationDecoration2} top="0" right="0" />
                <Decoration src={themeAndInformationDecoration3} bottom="0" right="0" />
            </Header>

            <Wrapper>
                {photoAlbumData && !requestError && data
                    ? <FilledContent />
                    : <LoadIcon />
                }
            </Wrapper>
        </>
    );

    function FilledContent() {

        return (
            <>
                <SRLWrapper options={options}>
                    <PhotoGrid detail="true">
                        {data.map(item => {
                            return(
                                <PhotoGridItem key={item.id} link={item.id} image={item.imageUrl} />
                                )
                            })}
                    </PhotoGrid>
                </SRLWrapper>
            </>
        )
    }
}

export default PhotoAlbum
